import React, { useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import 'jspdf-autotable'; // Import jspdf-autotable for table rendering in PDF
import TotalMembersEdit from './TotalMembersEdit';


const TotalMemberData = ({memberData, handleEdit, formData, handleInputChange}) => {

    const [searchTerm, setSearchTerm] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(null); // Default rows per page
  const [loading, setLoading] = useState(false); // State to manage loading indicator

  useEffect(() => {
    // Set rowsPerPage to the total number of rows initially
    if (memberData && memberData.length > 0) {
      setRowsPerPage(memberData.length);
    }
  }, [memberData]);

  if (!Array.isArray(memberData)) {
    // Convert non-array data to an array
    memberData = [memberData];
  }

  if (!memberData || memberData.length === 0) {
    return <div>No data available</div>;
  }

  const filteredData = memberData.filter((data) => {
    return (
      (data.accountNo && data.accountNo.toString().includes(searchTerm.toString())) ||
      (data.fullName && data.fullName.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  });

  const handleRowsPerPageChange = (e) => {
    const value = e.target.value;
    setRowsPerPage(value === "all" ? filteredData.length : parseInt(value));
  };

  const downloadPDF = async () => {
    setLoading(true); // Show loading indicator
    try {
      const doc = new jsPDF();

      // Calculate the height of the table content
      const input = document.getElementById('pdf-container');
      const { width, height } = input.getBoundingClientRect();

      // Set the dimensions of the canvas to match the dimensions of the table
      const canvas = await html2canvas(input, { width, height });
      const imgData = canvas.toDataURL('image/png');

      // Add image to PDF with appropriate dimensions
      const imgWidth = doc.internal.pageSize.getWidth();
      const imgHeight = (height * imgWidth) / width;
      doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

      doc.save('userdata.pdf');
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      setLoading(false); // Hide loading indicator
    }
  };

  const downloadExcel = () => {
    const table = document.getElementById('pdf-container');
    const ws = XLSX.utils.table_to_sheet(table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, 'userdata.xlsx');
  };


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB'); // Adjust locale based on your preference
  };

  return (
    <div>

    <div className="dt-buttons"  style={{zIndex:"1000"}}>
    
    
      
            {/* <button className="dt-button buttons-excel buttons-html5" onClick={downloadExcel}>
              <span>Excel</span>
            </button>
            <button className="dt-button buttons-pdf buttons-html5" onClick={downloadPDF}>
              <span>PDF</span>
            </button> */}
              <a
           style={{zIndex:"1000"}}
             className="dt-button buttons-excel buttons-html5"
             onClick={downloadExcel}
             tabIndex="0"
             aria-controls="DataTables_Table_1"
             href="#"
           >
             <span>Excel</span>
           </a>
           <a
             className="dt-button buttons-pdf buttons-html5"
             onClick={downloadPDF}
             tabIndex="0"
             aria-controls="DataTables_Table_1"
             href="#"
           >
             <span>PDF</span>
           </a>
    
    
          </div>
          <div className="sticky-top" style={{ display: "flex", marginTop: "10px" }}>
            <div id="DataTables_Table_1_wrapper" className="dataTables_wrapper form-inline dt-bootstrap">
              <div id="DataTables_Table_1_filter" className="dataTables_filter">
                <label>
                  Search:
                  <input
                    type="search"
                    className="form-control input-sm"
                    placeholder=""
                    aria-controls="DataTables_Table_1"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </label>
              </div>
    
              <label>Show entries
                <select
                  name="DataTables_Table_0_length"
                  aria-controls="DataTables_Table_0"
                  onChange={handleRowsPerPageChange}
                  value={rowsPerPage === filteredData.length ? "all" : rowsPerPage}
                  className="form-control input-sm"
                >
                  <option value="all">All</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                entries
              </label>
            </div>
          </div>
    
          <div id="pdf-container">
            {loading ? (
              <p>Loading...</p>
            ) : (
    
          <table>
            <thead>
              <tr>
                <th>Account No</th>
                <th>Full Name</th>
                <th>Department</th>
                <th>Designation</th>
                <th>DOB</th>
                <th>DOJ</th>
                <th>DOR</th>
                <th>Mobile Number</th>
                <th>Email</th>
                <th>Address</th>
                <th>Shares</th>
                <th>Monthly Deposit</th>
                <th>insurance</th>
                <th>Bank Account Number</th>
                <th>Bank Name</th>
                <th>Branch Code</th>
                <th>Branch Name</th>
                <th>Nominee Name</th>
                <th>Relation With Nominee</th>
                <th>Reg Fees</th>
                {/* <th>Action</th> */}

              </tr>
            </thead>
            <tbody>
    
        
            {filteredData.slice(0, rowsPerPage).map((data, index) => (
                <tr key={index}>
                  <td>{data.accountNo}</td>
                  <td>{data.fullName}</td>
                  <td>{data.department}</td>
                  <td>{data.designation}</td>
                  <td>{formatDate(data.dob)}</td>
                  <td>{formatDate(data.doj)}</td>
                  <td>{ formatDate(data.dor)}</td>

                  <td>{data.mobileNumber}</td>
                  <td>{data.emailid}</td>
                  <td>{data.address}</td>
                  <td>{data.shares}</td>
                  <td>{data.monthlyDeposit}</td>
                  <td>{data.insurance}</td>
                  <td>{data.bankAccountNo}</td>

                  <td>{data.bankName}</td>
                  <td>{data.branchCode}</td>
                  <td>{data.branchName}</td>
                  <td>{data.nomineeName}</td>
                  <td>{data.relationWithNominee}</td>
                  <td>{data.regFees}</td>
                  {/* <td><TotalMembersEdit formData={formData} handleInputChange={handleInputChange}  handleEdit={() => handleEdit(data.fdId)}/></td> */}
                </tr>
              ))}
            
              
              
              
            </tbody>
          </table>
          )}
          </div>
        </div>
  )
}

export default TotalMemberData
