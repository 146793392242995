import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import AdminTopBar from '../../AdminTopBar/AdminTopBar'
import AdminSideBar from '../../AdminSideBar/AdminSideBar'
import { RiFolderOpenLine } from "react-icons/ri";
import { FcSimCardChip, FcCancel } from "react-icons/fc";
import XeroxCurrentAccountModal from './XeroxCurrentAccountModal';
import { useForm } from 'react-hook-form'
import { Submit } from '../../Service';
import { Button, Form } from 'react-bootstrap'
import axios from 'axios';
import UserXeroxD from './UserXeroxD';



const XeroxCurrentAccount = () => {
  const { handleSubmit, register, reset, formState: { errors } } = useForm();
  const [users, setUsers] = useState([])



  // Function to handle form submission
  const onSubmit = async (data) => {
    console.log("data", data);
    try {
      // const response = await axios.post('/api/DeadMember', data);
      const response = await axios.post('https://103.50.212.98:4431/api/XeroxDEpartment', data);

      console.log('Response status:', response.status); // Logging only the status
      // Handle success response here
      reset();
    } catch (error) {
      console.error('Error:', error);
      // Handle error response here
    }

  };

  const fetchUsers = async (url) => {
    try {
      const res = await fetch(url);

      const Tdata = await res.json();
      console.log(Tdata, "my table data"); // Log the data here to inspect its structure
      setUsers(Tdata); // Set the fetched data to the users state
    } catch (error) {
      console.log(error);
    }
  };



  const API = "https://103.50.212.98:4431/api/XeroxDEpartment"
  useEffect(() => {
    fetchUsers(API)
  }, [])


  return (
    <div>

      <div className="theme-red">
        {/* <!-- Page Loader --> */}
        {/* <Loader /> */}
        {/* <!-- #END# Page Loader -->
   
   
   
   
   
  <!-- Search Bar --> */}
        <div className="search-bar">
          <div className="search-icon">
            <i className="material-icons">search</i>
          </div>
          <input type="text" placeholder="START TYPING..." />
          <div className="close-search">
            <i className="material-icons">close</i>
          </div>

        </div>
        {/* <!-- #END# Search Bar -->
   
   
   
   
  <!-- Top Bar --> */}
        <AdminTopBar />
        {/* <!-- #Top Bar --> */}



        {/* sideBar */}


        <AdminSideBar />
        {/* end sideBar */}

        <section className="content">
          <Link to="/">  <h4 >DASHBOARD</h4></Link>
          <div className="container-fluid">
            <div className="block-header">

            </div>






            {/* <!-- Widgets --> */}
            <div className="row clearfix">

              <Link to="totalshareamount">
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                  <div className="info-box bg-pink hover-expand-effect">
                    <div className="icon">
                      <RiFolderOpenLine style={{ fontSize: '70' }} />
                      {/* <i className="material-icons">playlist_add_check</i> */}
                    </div>
                    <div className="content">
                      <div className="text">Opening Balance : 405000</div>
                      <p>View Detail</p>
                      <div
                        className="number count-to"
                        data-from="0"
                        data-to="125"
                        data-speed="15"
                        data-fresh-interval="20"
                      ></div>
                    </div>
                  </div>
                </div>
              </Link>



              <Link to="monthlybalance">
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                  <div className="info-box bg-cyan hover-expand-effect">
                    <div className="icon">
                      <FcSimCardChip style={{ fontSize: '70' }} />
                      {/* <i className="material-icons">monetization_on</i> */}
                    </div>
                    <div className="content">
                      <div className="text">Credit Amount : 0.0</div>
                      <p>Debit Aamount: 0.0</p>
                      <div
                        className="number count-to"
                        data-from="0"
                        data-to="257"
                        data-speed="1000"
                        data-fresh-interval="20"
                      ></div>
                    </div>
                  </div>
                </div>
              </Link>

              <Link to="dashboardinsurence">
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                  <div className="info-box bg-light-green hover-expand-effect">
                    <div className="icon">
                      <FcCancel style={{ fontSize: '70' }} />
                      {/* <i className="material-icons">enhanced_encryption</i> */}
                    </div>
                    <div className="content">
                      <div className="text">Closing Balance : 405000</div>
                      <p>View Detail</p>
                      <div
                        className="number count-to"
                        data-from="0"
                        data-to="243"
                        data-speed="1000"
                        data-fresh-interval="20"
                      ></div>
                    </div>
                  </div>
                </div>
              </Link>




            </div>
            {/* <!-- #END# Widgets --> */}






            {/* form starts */}
            {/* <!-- Masked Input --> */}


            <div className="row clearfix">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2>Other Third Party Payment Form</h2>
                  </div>
                  <div className="body">
                    <div className="demo-masked-input">
                      <div className="row clearfix">
                        <Form onSubmit={handleSubmit(onSubmit)}>


                          {/* input */}
                          <div className="col-md-8">
                            <b>Select Party Name</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">event_note</i>
                              </span>
                              <div className="form-line">
                                {/* dropdown form start */}
                                <div className="col-4">
                                  <select id="partyname" name="partyName"
                                    {...register("partyName")}
                                    className="form-control">

                                    <option value="ज़ीरॉक्स खर्च">ज़ीरॉक्स खर्च</option>
                                    <option value="बॅंक करंट ज़ीरॉक्स">बॅंक करंट ज़ीरॉक्स</option>
                                    <option value="जी.एस.टी. खर्च">जी.एस.टी. खर्च</option>
                                    <option value="ज़ीरॉक्स विभाग निव्वल ऩफा">ज़ीरॉक्स विभाग निव्वल ऩफा</option>
                                    <option value="जी.एस.टी. शुल्क">जी.एस.टी. शुल्क</option>
                                    <option value="XEROX PROFIT">XEROX PROFIT</option>
                                    <option value="स्टेशनरी खर्च">स्टेशनरी खर्च</option>

                                  </select>
                                </div>
                                {/* dropdown form ends */}
                              </div>
                            </div>
                          </div>
                          {/* input */}



                          <div className="col-md-8">
                            <b>Account Number</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                              <input
                                  type="text"
                                  name='accountNo'
                                  className={`form-control date ${errors.accountNo ? 'is-invalid' : ''}`}
                                  placeholder="Enter Account Number"
                                  {...register("accountNo", { pattern: /^[0-9]*$/ })}
                                />
                              </div>
                            </div>
                              {errors.accountNo && errors.accountNo.type === "pattern" && (
                                  <div className="invalid-feedback text-red">Please enter only numbers</div>
                                )}
                          </div>






                          <div className="col-md-8">
                            <b>Amount</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <Form.Group className="mb-3" controlId="xeroxAmountField">
                                  <Form.Control
                                    type="number"
                                    name='amount'
                                    className="form-control time24"
                                    placeholder="Enter Amount"
                                    {...register("amount", { required: "please enter Amount" })}
                                  />
                                </Form.Group>
                              </div>
                            </div>
                            <p style={{ color: "red" }}>{errors?.amount?.message}</p>

                          </div>




                          <div className="col-md-8">
                            <b>Date</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <Form.Group className="mb-3" controlId="xeroxDateField">
                                  <Form.Control
                                    type="date"
                                    name='date'
                                    className="form-control date"
                                    {...register("date", { required: "please enter date" })}
                                  />
                                </Form.Group>
                              </div>
                            </div>
                            <p style={{ color: "red" }}>{errors?.date?.message}</p>
                          </div>




                          <div className="col-md-8">
                            <b>Cheque Number</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  name='chequeNo'
                                  {...register("chequeNo")}
                                  className="form-control date"
                                  placeholder="Enter Cheque Number"
                                />
                              </div>
                            </div>
                          </div>




                          <div className="col-md-8">
                            <b>Receipt Number</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  name='receiptNo'
                                  {...register("receiptNo")}
                                  className="form-control date"
                                  placeholder="Enter Receipt Number"
                                />
                              </div>
                            </div>
                          </div>





                          <div className="col-md-8">
                            <b>Transaction</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">

                                <div className="col-4">
                                  <select id="partyname" name="transaction"
                                    {...register("transaction")}
                                    className="form-control">

                                    <option value="debit">Debit</option>
                                    <option value="credit">Credit</option>


                                  </select>
                                </div>

                              </div>
                            </div>
                          </div>





                          <div className="col-md-8">
                            <b>Notes</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                {/* <input
                                type="number"
                                className="form-control date"
                                placeholder="Enter Receipt Number"
                              /> */}
                                <div className="col-4">
                                  <textarea className="form-control" id="notes" name="notes"
                                    {...register("notes")}
                                    rows="2" spellCheck="false"></textarea>
                                </div>
                              </div>
                            </div>
                          </div>



                          {/* <Button type="button" className="btn bg-indigo btn-block btn-lg waves-effect">Submit</Button> */}
                          <Button type="submit" variant='primary' className="btn btn-block btn-lg waves-effect">Report</Button>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- #END# Masked Input --> */}
            {/* form starts */}








            {/* Table start */}

            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2>VIEW PARTY DATA</h2>
                   
                  </div>
                  <div className="body">
                    <div className="table-responsive">
                      <div
                        id="DataTables_Table_1_wrapper"
                        className="dataTables_wrapper form-inline dt-bootstrap"
                      >
                       
                      
                        {/* <label>Show <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="form-control input-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option></select> entries</label> */}


                        <table
                          className="table table-bordered table-striped table-hover dataTable js-exportable"
                          id="DataTables_Table_1"
                          role="grid"
                          aria-describedby="DataTables_Table_1_info"
                        >
                        
                          <tbody>
                            <UserXeroxD Users={users} />

                            
                          </tbody>
                        </table>
                        <div
                          className="dataTables_info"
                          id="DataTables_Table_1_info"
                          role="status"
                          aria-live="polite"
                        >
                          Showing 1 to 10 of 57 entries
                        </div>
                        <div
                          className="dataTables_paginate paging_simple_numbers"
                          id="DataTables_Table_1_paginate"
                        >
                          <ul className="pagination">
                            <li
                              className="paginate_button previous disabled"
                              id="DataTables_Table_1_previous"
                            >
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="0"
                                tabIndex="0"
                              >
                                Previous
                              </a>
                            </li>
                            <li className="paginate_button active">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="1"
                                tabIndex="0"
                              >
                                1
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="2"
                                tabIndex="0"
                              >
                                2
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="3"
                                tabIndex="0"
                              >
                                3
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="4"
                                tabIndex="0"
                              >
                                4
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="5"
                                tabIndex="0"
                              >
                                5
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="6"
                                tabIndex="0"
                              >
                                6
                              </a>
                            </li>
                            <li
                              className="paginate_button next"
                              id="DataTables_Table_1_next"
                            >
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="7"
                                tabIndex="0"
                              >
                                Next
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  Table end */}















          </div>
        </section>
      </div>

    </div>
  )
}

export default XeroxCurrentAccount
