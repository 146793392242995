import  {React, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Form } from 'react-bootstrap'
import AdminTopBar from '../../AdminTopBar/AdminTopBar'
import AdminSideBar from '../../AdminSideBar/AdminSideBar'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import UserRegData from './UserRegData'

const Registration = () => {
  const { handleSubmit, register,formState: { errors }, reset } = useForm();
  const [users, setUsers] = useState([])


  // Function to handle form submission
  const onSubmit = async (data) => {
    console.log("data", data);
    try {
      // const response = await axios.post('/api/DeadMember', data);
      const response = await axios.post('https://103.50.212.98:4431/api/Employee/post', data);

      console.log('Response status:', response.status); // Logging only the status
      // Handle success response here
      reset();
    } catch (error) {
      console.error('Error:', error);
      // Handle error response here
    }

  };
  const fetchUsers = async (url) => {
    try {
      const res = await axios.get(url); // Use axios.get instead of fetch
      const Tdata = res.data; // Axios response data is directly accessible as res.data
      console.log(Tdata, "registration table data"); // Log the data here to inspect its structure
      setUsers(Tdata); // Set the fetched data to the users state
    } catch (error) {
      console.log(error);
    }
  };

  const API = "https://103.50.212.98:4431/api/Employee/get";

  useEffect(() => {
    fetchUsers(API);
  }, []);
  return (
    <div>
      <div className="theme-red">
        {/* <!-- Page Loader --> */}
        {/* <Loader /> */}
        {/* <!-- #END# Page Loader -->





<!-- Search Bar --> */}
        <div className="search-bar">
          <div className="search-icon">
            <i className="material-icons">search</i>
          </div>
          <input type="text" placeholder="START TYPING..." />
          <div className="close-search">
            <i className="material-icons">close</i>
          </div>

        </div>
        {/* <!-- #END# Search Bar -->




<!-- Top Bar --> */}
        <AdminTopBar />
        {/* <!-- #Top Bar --> */}



        {/* sideBar */}


        <AdminSideBar />
        {/* end sideBar */}

        <section className="content">
          <Link to="/">  <h4 >DASHBOARD</h4></Link>
          <div className="container-fluid">
            <div className="block-header">

            </div>


            {/* form starts */}
            {/* <!-- Masked Input --> */}
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="row clearfix">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="card">
                    <div className="header">
                      <h2>Employee Registration Form</h2>
                    </div>
                    <div className="body">
                      <div className="demo-masked-input">
                        <div className="row clearfix">



                          <div className="col-md-8">
                            <b>First Name</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">event_note</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="text"
                                  name='fullName'
                                  className="form-control time24"
                                  placeholder='Enter First name'
                                  {...register("fullName")}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-8">
                            <b>Account Number</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">event_note</i>
                              </span>
                              <div className="form-line">
                              <input
                                  type="text"
                                  name='accountNo'
                                  className={`form-control date ${errors.accountNo ? 'is-invalid' : ''}`}
                                  placeholder="Enter Account Number"
                                  {...register("accountNo", { pattern: /^[0-9]*$/ })}
                                />
                              </div>
                              {errors.accountNo && errors.accountNo.type === "pattern" && (
                                  <div className="invalid-feedback text-red">Please enter only numbers</div>
                                )}
                            </div>
                          </div>



                          <div className="col-md-8">
                            <b>Designation</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">event_note</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="text"
                                  name='designation'
                                  {...register("designation")}
                                  className="form-control time24"
                                  placeholder='Enter Designation'
                                />
                              </div>
                            </div>
                          </div>



                          <div className="col-md-8">
                            <b>Date of Birth</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">event_note</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="date"
                                  name='dob'
                                  {...register("dob")}
                                  className="form-control time24"

                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-8">
                            <b>Date of Joining</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">event_note</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="date"
                                  name='doj'
                                  {...register("doj")}

                                  className="form-control time24"
                                  placeholder='Enter Date of joining'

                                />
                              </div>
                            </div>
                          </div>



                          <div className="col-md-8">
                            <b>Date of Retirement</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">event_note</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="date"
                                  name='dor'
                                  {...register("dor")}

                                  className="form-control time24"
                                  placeholder=' Enter Date of Retirement'
                                />
                              </div>
                            </div>
                          </div>



                          <div className="col-md-8">
                            <b>Mobile Number</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">event_note</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  name='mobileNumber'
                                  {...register("mobileNumber")}

                                  className="form-control time24"
                                  placeholder='Enter Mobile Number'
                                />
                              </div>
                            </div>
                          </div>



                          <div className="col-md-8">
                            <b>email ID</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">event_note</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type='email'
                                  name='emailid'
                                  {...register("emailid")}
                                  className="form-control time24"
                                  placeholder='Enter Email Id'
                                />
                              </div>
                            </div>
                          </div>




                          <div className="col-md-8">
                            <b>Bank Account Number</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">event_note</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  name='bankAccountNo'
                                  {...register("bankAccountNo")}
                                  className="form-control time24"
                                  placeholder='Enter Bank Account Number'
                                />
                              </div>
                            </div>
                          </div>


                          <div className="col-md-8">
                            <b>City</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">event_note</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="Text"
                                  name='address'
                                  className="form-control time24"
                                  placeholder='Enter City'
                                  {...register("city")}

                                />
                              </div>
                            </div>
                          </div>



                          <div className="col-md-8">
                            <b>Insurence</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">event_note</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  name='insurance'
                                  {...register("insurance")}
                                  className="form-control time24"
                                  placeholder=' Enter Insurance'
                                />
                              </div>
                            </div>
                          </div>


                          <div className="col-md-8">
                            <b>Password</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">event_note</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="password"
                                  name='password'
                                  {...register("password")}
                                  className="form-control time24"
                                  placeholder='Enter Password'
                                />
                              </div>
                            </div>
                          </div>


                          {/* <button type="button" className="btn bg-indigo btn-block btn-lg waves-effect">Report</button> */}

                          {/* <!-- Button trigger modal --> */}
                          <Button type="submit" variant='primary' className="btn btn-block btn-lg waves-effect ">Report</Button>










                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
            {/* <!-- #END# Masked Input --> */}
            {/* form starts */}
            {/* Table start */}

            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2>VIEW PARTY DATA</h2>
                    {/* <ul className="header-dropdown m-r--5">
                                <li className="dropdown">
                                    <a href="javascript:void(0);" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                        <i className="material-icons">more_vert</i>
                                    </a>
                                    <ul className="dropdown-menu pull-right">
                                        <li><a href="javascript:void(0);" className=" waves-effect waves-block">Action</a></li>
                                        <li><a href="javascript:void(0);" className=" waves-effect waves-block">Another action</a></li>
                                        <li><a href="javascript:void(0);" className=" waves-effect waves-block">Something else here</a></li>
                                    </ul>
                                </li>
                            </ul> */}
                  </div>
                  <div className="body">
                    <div className="table-responsive">
                      <div
                        id="DataTables_Table_1_wrapper"
                        className="dataTables_wrapper form-inline dt-bootstrap"
                      >
                      


                        {/* <label>Show <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="form-control input-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option></select> entries</label> */}


                        <table
                          className="table table-bordered table-striped table-hover dataTable js-exportable"
                          id="DataTables_Table_1"
                          role="grid"
                          aria-describedby="DataTables_Table_1_info"
                        >


                          <tbody>
                            <UserRegData Users={users} />


                          </tbody>
                        </table>
                        <div
                          className="dataTables_info"
                          id="DataTables_Table_1_info"
                          role="status"
                          aria-live="polite"
                        >
                          Showing 1 to 10 of 57 entries
                        </div>
                        <div
                          className="dataTables_paginate paging_simple_numbers"
                          id="DataTables_Table_1_paginate"
                        >
                          <ul className="pagination">
                            <li
                              className="paginate_button previous disabled"
                              id="DataTables_Table_1_previous"
                            >
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="0"
                                tabIndex="0"
                              >
                                Previous
                              </a>
                            </li>
                            <li className="paginate_button active">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="1"
                                tabIndex="0"
                              >
                                1
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="2"
                                tabIndex="0"
                              >
                                2
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="3"
                                tabIndex="0"
                              >
                                3
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="4"
                                tabIndex="0"
                              >
                                4
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="5"
                                tabIndex="0"
                              >
                                5
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="6"
                                tabIndex="0"
                              >
                                6
                              </a>
                            </li>
                            <li
                              className="paginate_button next"
                              id="DataTables_Table_1_next"
                            >
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="7"
                                tabIndex="0"
                              >
                                Next
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  Table end */}



          </div>
        </section>
      </div>
    </div>
  )
}

export default Registration
