import React, { useEffect, useState } from 'react';
 import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import 'jspdf-autotable'; // Import jspdf-autotable for table rendering in PDF


const DeathMemberData = ({ Users }) => {
    console.log("this is death member form data", Users);


    const [searchTerm, setSearchTerm] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(null); // Default rows per page
    const [loading, setLoading] = useState(false); // State to manage loading indicator
  
    useEffect(() => {
      // Set rowsPerPage to the total number of rows initially
      if (Users && Users.result && Users.result.length > 0) {
        setRowsPerPage(Users.result.length);
      }
    }, [Users]);
  
    if (!Users || !Users.result || Users.result.length === 0) {
      return <tr><td colSpan="3">No data available</td></tr>;
    }
  
  
    const filteredUsers = Users.result.filter((user) => {
      return (
        (user.accountNo && user.accountNo.toString().includes(searchTerm.toString())) ||
        (user.fullName && user.fullName.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    });
  
    const handleRowsPerPageChange = (e) => {
      const value = e.target.value;
      setRowsPerPage(value === "all" ? filteredUsers.length : parseInt(value));
    };
  
    const downloadPDF = async () => {
      setLoading(true); // Show loading indicator
      try {
          const doc = new jsPDF();
  
          // Calculate the height of the table content
          const input = document.getElementById('pdf-container');
          const { width, height } = input.getBoundingClientRect();
  
          // Set the dimensions of the canvas to match the dimensions of the table
          const canvas = await html2canvas(input, { width, height });
          const imgData = canvas.toDataURL('image/png');
  
          // Add image to PDF with appropriate dimensions
          const imgWidth = doc.internal.pageSize.getWidth();
          const imgHeight = (height * imgWidth) / width;
          doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
  
          doc.save('userdata.pdf');
      } catch (error) {
          console.error('Error generating PDF:', error);
      } finally {
          setLoading(false); // Hide loading indicator
      }
    };
  
    const downloadExcel = () => {
      const table = document.getElementById('pdf-container');
      const ws = XLSX.utils.table_to_sheet(table);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, 'userdata.xlsx');
    };
  
  return (
    <div>
    <div style={{zIndex:"1000"}} className="dt-buttons">
     

     <a
     style={{zIndex:"1000"}}
       className="dt-button buttons-excel buttons-html5"
       onClick={downloadExcel}
       tabIndex="0"
       aria-controls="DataTables_Table_1"
       href="#"
     >
       <span>Excel</span>
     </a>
     <a
       className="dt-button buttons-pdf buttons-html5"
       onClick={downloadPDF}
       tabIndex="0"
       aria-controls="DataTables_Table_1"
       href="#"
     >
       <span>PDF</span>
     </a>
    
   </div>
   <div className="sticky-top " style={{ display: "flex", marginTop: "10px" }}>
     <div id="DataTables_Table_1_wrapper" className="dataTables_wrapper form-inline dt-bootstrap">
       <div id="DataTables_Table_1_filter" className="dataTables_filter">
         <label>
           Search:
           <input
             type="search"
             className="form-control input-sm"
             placeholder=""
             aria-controls="DataTables_Table_1"
             value={searchTerm}
             onChange={(e) => setSearchTerm(e.target.value)}
           />
         </label>
       </div>

       <label>Show entries
         <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0"
           onChange={handleRowsPerPageChange}
           value={rowsPerPage === filteredUsers.length ? "all" : rowsPerPage}

           className="form-control input-sm">
           <option value="all">All</option>
           <option value="10">10</option>
           <option value="25">25</option>
           <option value="50">50</option>
           <option value="100">100</option>
         </select> entries
       </label>
     </div>
   </div>


<div id="pdf-container">

{loading ? (
<p>Loading...</p>
) : (
    <table>
      <thead>
        <tr>
          <th>Account Number</th>
          <th>Account Name</th>
          <th>Bank Account Number</th>
          <th>Loan Amount</th> 
          <th>Shares</th>
          <th>Monthly Deposite</th>
          <th>Insurence</th>
          <th>10 Added Amount</th>
          <th>RD</th>
          <th>FD</th>
          <th>Regular Loan</th>
          <th>Regular Loan Interest</th>
          <th>Emergancy Loan</th>
          <th>Emergancy Loan Interest</th>
          <th>Total Amount</th>
          <th>Payable Amount</th>
          <th>Cheque Number</th>
          <th>Cheque Date</th>
          <th>Pay to</th>
          {/* <th>nomineeName1</th>
          <th>nomineeName2</th> */}
          
        </tr>
      </thead>
      <tbody>
        {/* {Users.result.map((curlUser, index) => { */}
        {filteredUsers.slice(0, rowsPerPage).map((curlUser, index) => {
          const {accountNo,accountName,salaryAccount, deathDate, shares, monthlyDeposit,insurance,_10AddedAmount,
            rd,fd,regularLoan,regLoanInterest,emergancyLoan,emgLoanInterest,totalAmount,payableAmount,chequeNo,chequeDate,
            payTo,nomineeName1,nomineeName2
        } = curlUser;
          return (
            <tr className="tableRow" key={index}>
              <td>{accountNo}</td>
              <td>{accountName}</td>
              <td>{salaryAccount}</td>
              <td>{deathDate}</td>
              <td>{shares}</td>
              <td>{monthlyDeposit}</td>
              <td>{insurance}</td>
              <td>{_10AddedAmount}</td>
              <td>{rd}</td>
              <td>{fd}</td>
              <td>{regularLoan}</td>
              <td>{regLoanInterest}</td>
              <td>{emergancyLoan}</td>
              <td>{emgLoanInterest}</td>
              <td>{totalAmount}</td>
              <td>{payableAmount}</td>
              <td>{chequeNo}</td>
              <td>{chequeDate}</td>
              <td>{payTo}</td>
              {/* <td>{nomineeName1}</td>
              <td>{nomineeName2}</td> */}
            </tr>
          );
        })}
      </tbody>
    </table>
     )}
     </div>
  </div>
  )
}

export default DeathMemberData
