import React, { useEffect, useState } from 'react'
import AdminTopBar from '../../AdminTopBar/AdminTopBar'
import AdminSideBar from '../../AdminSideBar/AdminSideBar'
import { Link } from 'react-router-dom'
import { Button, Form } from 'react-bootstrap'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import PathsData from './PathsData'



const PathsansthaProfile = () => {
  const { handleSubmit, register, reset } = useForm();
  const [users, setUsers] = useState([])


  // Function to handle form submission
  const onSubmit = (data) => {
    console.log("data", data);

    axios.post('https://103.50.212.98:4431/api/PatsansthaProfile/post', data)

      .then((response) => {
        console.log('ye Response:', response.data);
        // Handle success response here
        console.log(data);
        reset();
      })
      .catch((error) => {
        console.error('Error:', error);
        // Handle error response here
      });

  };
  const fetchUsers = async (url) => {
    try {
      const res = await axios.get(url); // Use axios.get instead of fetch
      const Tdata = res.data; // Axios response data is directly accessible as res.data
      console.log(Tdata, "my table data"); // Log the data here to inspect its structure
      setUsers(Tdata); // Set the fetched data to the users state
    } catch (error) {
      console.log(error);
    }
  };

  const API = "https://103.50.212.98:4431/api/PatsansthaProfile/get";

  useEffect(() => {
    fetchUsers(API);
  }, []);
  return (
    <div>

      <div className="theme-red">
        {/* <!-- Page Loader --> */}
        {/* <Loader /> */}
        {/* <!-- #END# Page Loader -->
   
   
   
   
   
  <!-- Search Bar --> */}
        <div className="search-bar">
          <div className="search-icon">
            <i className="material-icons">search</i>
          </div>
          <input type="text" placeholder="START TYPING..." />
          <div className="close-search">
            <i className="material-icons">close</i>
          </div>

        </div>
        {/* <!-- #END# Search Bar -->
   
   
   
   
  <!-- Top Bar --> */}
        <AdminTopBar />
        {/* <!-- #Top Bar --> */}



        {/* sideBar */}


        <AdminSideBar />
        {/* end sideBar */}

        <section className="content">
          <Link to="/">  <h4 >DASHBOARD</h4></Link>
          <div className="container-fluid">
            <div className="block-header">

            </div>


            {/* form starts */}
            {/* <!-- Masked Input --> */}
            <div className="row clearfix">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2>Patsanstha Profile (पतसंस्था माहिती)</h2>
                  </div>
                  <div className="body">
                    <div className="demo-masked-input">
                      <div className="row clearfix">

                        <Form onSubmit={handleSubmit(onSubmit)}>


                          <div className="col-md-8">
                            <b>Patsanstha Name (पूर्ण नाव)</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">event_note</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="text"
                                  name='patsansthaName'
                                  {...register("patsansthaName")}
                                  className="form-control time24"
                                  placeholder="Enter Pathsanstha Name"
                                />
                              </div>
                            </div>
                          </div>


                          <div className="col-md-8">
                            <b>Registration Noumber</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">event_note</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  name='registrationNo'
                                  {...register("registrationNo")}
                                  className="form-control time24"
                                  placeholder="Enter Registration Noumber"
                                />
                              </div>
                            </div>
                          </div>


                          <div className="col-md-8">
                            <b>Patsanstha Address (पत्ता)</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">event_note</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="text"
                                  name='patsansthaAddress'
                                  {...register("patsansthaAddress")}
                                  className="form-control time24"
                                  placeholder="Enter Patsanstha Address (पत्ता)"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-8">
                            <b>City</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">event_note</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="text"
                                  name='city'
                                  {...register("city")}
                                  className="form-control time24"
                                  placeholder="Enter City Name"
                                />
                              </div>
                            </div>
                          </div>


                          <div className="col-md-8">
                            <b>State</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">event_note</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="text"
                                  name='state'
                                  {...register("state")}
                                  className="form-control time24"
                                  placeholder="Enter State"
                                />
                              </div>
                            </div>
                          </div>



                          <div className="col-md-8">
                            <b>Pincode</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">event_note</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  name='pincode'
                                  {...register("pincode")}
                                  className="form-control time24"
                                  placeholder="Enter Pincode"
                                />
                              </div>
                            </div>
                          </div>


                          <div className="col-md-8">
                            <b>Year of Establishment</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">event_note</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="date"
                                  name='yearOfEstablish'
                                  // {...register("yearOfEstablish")}
                                  {...register("yearOfEstablish", { required: "please enter Date" })}
                                  className="form-control time24"

                                />
                              </div>
                            </div>
                          </div>



                          <div className="col-md-8">
                            <b>Mobile Number</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">event_note</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  name='contactNo'
                                  {...register("contactNo")}
                                  className="form-control time24"
                                  placeholder="Enter Mobile"
                                />
                              </div>
                            </div>
                          </div>



                          <div className="col-md-8">
                            <b>email id</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">event_note</i>
                              </span>
                              <div className="form-l ine">
                                <input
                                  type="email"
                                  name='emailId'
                                  {...register("emailId")}
                                  className="form-control time24"
                                  placeholder="Enter email id"
                                />
                              </div>
                            </div>
                          </div>


                          <div className="col-md-8">
                            <b>GST Number</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">event_note</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  name='gstno'
                                  {...register("gstno")}
                                  className="form-control time24"
                                  placeholder="Enter GST Number"
                                />
                              </div>
                            </div>
                          </div>


                          <div className="col-md-8">
                            <b>Pan Noumber</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">event_note</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  name='panNo'
                                  {...register("panNo")}
                                  className="form-control time24"
                                  placeholder="Enter Pan Noumber"
                                />
                              </div>
                            </div>
                          </div>



                          <div className="col-md-8">
                            <b>Website</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">event_note</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="text"
                                  name='website'
                                  {...register("website")}
                                  className="form-control time24"
                                  placeholder="Enter Website URL"
                                />
                              </div>
                            </div>
                          </div>



                          <Button type="submit" variant='primary' className="btn btn-block btn-lg waves-effect">Report</Button>
                        </Form>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- #END# Masked Input --> */}
            {/* form starts */}

            {/* Table start */}

            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2>VIEW PARTY DATA</h2>

                  </div>
                  <div className="body">
                    <div className="table-responsive">
                      <div
                        id="DataTables_Table_1_wrapper"
                        className="dataTables_wrapper form-inline dt-bootstrap"
                      >
                       
                        {/* <label>Show <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="form-control input-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option></select> entries</label> */}


                        <table
                          className="table table-bordered table-striped table-hover dataTable js-exportable"
                          id="DataTables_Table_1"
                          role="grid"
                          aria-describedby="DataTables_Table_1_info"
                        >
                        
                          <tbody>
                            <PathsData Users={users} />

                           
                          </tbody>
                        </table>
                        <div
                          className="dataTables_info"
                          id="DataTables_Table_1_info"
                          role="status"
                          aria-live="polite"
                        >
                          Showing 1 to 10 of 57 entries
                        </div>
                        <div
                          className="dataTables_paginate paging_simple_numbers"
                          id="DataTables_Table_1_paginate"
                        >
                          <ul className="pagination">
                            <li
                              className="paginate_button previous disabled"
                              id="DataTables_Table_1_previous"
                            >
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="0"
                                tabIndex="0"
                              >
                                Previous
                              </a>
                            </li>
                            <li className="paginate_button active">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="1"
                                tabIndex="0"
                              >
                                1
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="2"
                                tabIndex="0"
                              >
                                2
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="3"
                                tabIndex="0"
                              >
                                3
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="4"
                                tabIndex="0"
                              >
                                4
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="5"
                                tabIndex="0"
                              >
                                5
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="6"
                                tabIndex="0"
                              >
                                6
                              </a>
                            </li>
                            <li
                              className="paginate_button next"
                              id="DataTables_Table_1_next"
                            >
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="7"
                                tabIndex="0"
                              >
                                Next
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  Table end */}

          </div>
        </section>
      </div>

    </div>
  )
}

export default PathsansthaProfile
