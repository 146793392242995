import React from 'react'
import CloseFDmodal from './CloseFDmodal'

const CloseFDData = ({ fdDetails, handleEdit, formData, handleInputChange }) => {
  return (
    <div>
      <table className="table table-bordered table-striped table-hover dataTable js-exportable">
      <thead>
        <tr>
          <th>ID</th>
          <th>Account Number</th>
          <th>FD Certificate Number</th>
          <th>Full Name</th>
          <th>FD Amount</th>
          <th>FD Start Date</th>
          <th>FD Close Date</th>
          <th>Total Amount</th>
          <th>Cheque Number</th>
          <th>Receipt Number</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {fdDetails.length === 0 ? (
          <tr>
            <td colSpan="12">No data available</td>
          </tr>
        ) : (
          fdDetails.map((details, index) => (
            <tr key={details.fdId}>
              <td>{index + 1}</td>
              <td>{details.accountNo}</td>
              <td>{details.fdCertificateNo}</td>
              <td>{details.accountName}</td>
              <td>{details.fdAmount}</td>
              <td>{details.fdDate}</td>
              <td>{details.fdCloseDate}</td>
              <td>{details.totalAmount}</td>
              <td>{details.chequeNo}</td>
              <td>{details.reciptNo}</td>
              <td><CloseFDmodal formData={formData} handleInputChange={handleInputChange}  handleEdit={() => handleEdit(details.fdId)} /></td>
              {/* <td>
                <button onClick={() => handleEdit(details.fdId)} className="btn btn-primary">Edit</button>
              </td> */}
            </tr>
          ))
        )}
      </tbody>
    </table>
  
    </div>
  )
}

export default CloseFDData
