


import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AdminSideBar from '../../AdminSideBar/AdminSideBar'
import AdminTopBar from '../../AdminTopBar/AdminTopBar'
import { useForm } from 'react-hook-form'
// import { Submit } from '../../Service';
import { Button, Form } from 'react-bootstrap'
import axios from 'axios'
import UserDclose from './UserDclose'


const CloseLoan = () => {
  
  const [users, setUsers] = useState([]);
  const [userData, setUserData] = useState(null);
  const [closeDateError, setCloseDateError] = useState(false);

  const [formData, setFormData] = useState({
    accountNo: '',
    fullName: '',
    balanceAmount: '',
    interestAmount: '',
    closeDate: '',
    chequeNo: '',
    paidAmount: '',
    description: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const fetchUserDetails = async (accountNo) => {
    try {
      const res = await axios.get(`https://103.50.212.98:4431/api/RegularLoanClose/getUserDetailsForLoanCLose?accountNo=${accountNo}`);
      const userData = res.data.result;
      setUserData(userData);
      setFormData({
        ...formData,
        fullName: userData.fullName,
        balanceAmount: userData.balanceAmount,
        paidAmount: userData.balanceAmount + formData.interestAmount, // Set paidAmount initially
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchInterestAmount = async () => {
    if (!formData.accountNo || !formData.closeDate) {
      alert('Please enter account number and date');
      return;
    }
    try {
      const res = await axios.get(`https://103.50.212.98:4431/api/RegularLoanClose/getInterestAmount?AccountNo=${formData.accountNo}&date=${formData.closeDate}`);
      const interestAmount = res.data.result;
      const paidAmount = parseFloat(formData.balanceAmount) + parseFloat(interestAmount); // Calculate paidAmount
      setFormData({
        ...formData,
        interestAmount: interestAmount,
        paidAmount: paidAmount, // Update paidAmount
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (event) => {
    console.log('Form Data:', formData);
    event.preventDefault();
   
    if (!formData.closeDate) {
      setCloseDateError(true);
    } else {
      setCloseDateError(false);
    }
    if (!formData.closeDate) {
      return;
    }
    try {
      const response = await axios.post(
        'https://103.50.212.98:4431/api/RegularLoanClose/closeLoan',
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
          },
        }
      );
      console.log('Form data submitted successfully:', response.data);
      alert("Form data submitted successfully");
      setFormData({
        accountNo: '',
        fullName: '',
        balanceAmount: '',
        interestAmount: '',
        closeDate: '',
        chequeNo: '',
        paidAmount: '',
        description: ''
      });
    } catch (error) {
      console.error('Error submitting form data:', error);
    }
  };
    





  // table data code
  const fetchUsers = async (url) => {
    try {
      const res = await axios.get(url); // Use axios.get instead of fetch
      const Tdata = res.data; // Axios response data is directly accessible as res.data
      console.log(Tdata, " Close loan table data"); // Log the data here to inspect its structure
      setUsers(Tdata); // Set the fetched data to the users state
    } catch (error) {
      console.log(error);
    }
  };

  const API = "https://103.50.212.98:4431/api/RegularLoanClose";

  useEffect(() => {
    fetchUsers(API);
  }, []);

   // Prevent accidental value changes on scroll
   const handleScroll = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div>
  
      <div className="theme-red">
        {/* <!-- Page Loader --> */}
        {/* <Loader /> */}
        {/* <!-- #END# Page Loader -->
   
   
   
   
   
  <!-- Search Bar --> */}
        <div className="search-bar">
          <div className="search-icon">
            <i className="material-icons">search</i>
          </div>
          <input type="text" placeholder="START TYPING..." />
          <div className="close-search">
            <i className="material-icons">close</i>
          </div>
       
        </div>
        {/* <!-- #END# Search Bar -->
   
   
   
   
  <!-- Top Bar --> */}
       <AdminTopBar/>
        {/* <!-- #Top Bar --> */}


        
{/* sideBar */}


<AdminSideBar  />
{/* end sideBar */}

        <section className="content">
             <Link to="/">  <h4 >DASHBOARD</h4></Link>
          <div className="container-fluid">
            <div className="block-header">
            
            </div>


          
              {/* form starts */}
            {/* <!-- Masked Input --> */}

            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="DedictionForm">
            <div className="row clearfix">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2>Close Loan</h2>
                  </div>
                  <div className="body">
                    <div className="demo-masked-input">
                      <div className="row clearfix">
                      {/* <Form onSubmit={handleSubmit(onSubmit)}> */}
                       
                     

                         <div className="col-md-8">
                          <b>Account Number (खाते क्रमांक)</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                            <input
                                  type="number"
                                  name='accountNo'
                                  className="form-control date"
                                  placeholder="Enter Account Number"
                                  value={formData.accountNo}
                                  onChange={handleInputChange}
                                />
                               <button type="button"
                                onClick={() => fetchUserDetails(formData.accountNo)}
                               className="btn bg-indigo btn-block btn-lg waves-effect primaryButton secondaryButton">Search</button>
                            </div>
                           
                          </div>
                        </div> 


                        {/* <button type="button" class="btn btn-primary ">Search</button> */}



                        <div className="col-md-8">
                          <b>Full Name (पूर्ण नाव)</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="text"
                                name='fullName'
                                className="form-control date"
                                placeholder="Enter Full Name (पूर्ण नाव)"
                                value={formData.fullName}
                                readOnly
                              />
                            </div>
                          </div>
                        </div> 



                       


                        <div className="col-md-8">
                          <b>Balance Loan Amount</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="number"
                                name='balanceAmount'
                                className="form-control date"
                                placeholder="Enter Balance Loan Amount"
                                value={formData.balanceAmount}
                                  readOnly
                             />
                            </div>
                          </div>
                        </div> 




                       
                        <div className="col-md-8">
                          <b>Date</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="date"
                                name='closeDate'
                                className={`form-control ${closeDateError ? 'is-invalid' : ''}`}
                                value={formData.closeDate}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          {closeDateError && <p style={{ color: "red" }}>Date is required</p>}
                        </div> 






                        <div className="col-md-8">
                              <b>Interest Amount</b>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="material-icons">person</i>
                                </span>
                                <div className="form-line">
                                  <input
                                    type="number"
                                    name='interestAmount'
                                    className="form-control date"
                                    placeholder="Enter Interest Amount"
                                    value={formData.interestAmount}
                                    readOnly
                                  />
                                  <button type="button"
                                    onClick={fetchInterestAmount}
                                    className="btn bg-indigo btn-block btn-lg waves-effect primaryButton secondaryButton">Date</button>
                                </div>
                              </div>
                            </div>




                      






                      
                        <div className="col-md-8">
                          <b>Cheque Number/Reciept Number</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="text"
                                name='description'
                                className="form-control date"
                                placeholder="Enter Cheque Number"
                                value={formData.description}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div> 



                       


                        <div className="col-md-8">
                          <b>Paid Amount</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="number"
                                name='paidAmount'
                                // className={`form-control ${paidAmountError ? 'is-invalid' : ''}`}
                                className="form-control date"
                                placeholder="Enter Paid Amount"
                                value={formData.paidAmount}
                                readOnly
                              />
                            </div>
                          </div>
                          {/* {paidAmountError && <p style={{ color: "red" }}>This field is required</p>} */}
                           </div> 


                        
                     

                                  
                        <Button type="submit" variant='primary' className="btn bg-indigo btn-block btn-lg waves-effect">Submit</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </Form.Group>
         </Form>
            {/* <!-- #END# Masked Input --> */}
            {/* form starts */}



          {/* Table start */}

          <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2> Close Loan</h2>
                   
                  </div>
                  <div className="body">
                    <div className="table-responsive">
                      <div
                        id="DataTables_Table_1_wrapper"
                        className="dataTables_wrapper form-inline dt-bootstrap"
                      >
                       

                        {/* <label>Show <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="form-control input-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option></select> entries</label> */}


                        <table
                          className="table table-bordered table-striped table-hover dataTable js-exportable"
                          id="DataTables_Table_1"
                          role="grid"
                          aria-describedby="DataTables_Table_1_info"
                        >
                        
                        
                        <tbody>
                          <UserDclose Users={users}/>
                        </tbody>
                        </table>
                       

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
         {/*  Table end */}






          </div>
        </section>
      </div>
    </div>
  )
}

export default CloseLoan
