import axios from 'axios';
import React, { useEffect, useState } from 'react'

const CloseFDmodal = ({ handleEdit ,handleInputChange,formData}) => {
  // const [successMessage, setSuccessMessage] = useState('');

  const handleDelete = () => {
    // Prepare the data for the delete request
    const deleteData = {
      fdId: formData.fdId,
      fdYear: formData.fdDate, // Assuming fdYear is equivalent to fdDate
      fdCertificateNo: formData.fdCertificateNo,
      accountNo: formData.accountNo,
      accountName: formData.accountName,
      fdAmount: formData.fdAmount,
      fdDate: formData.fdDate,
      fdCloseDate: formData.fdCloseDate,
      reciptNo: formData.reciptNo,
      chequeNo: formData.chequeNo,
      isDelete: true,
      changeInterestId: formData.changeInterestId,
      interestAoumnt: formData.interestAmount,
      totalFdamount: formData.totalAmount,
      totalAmount: formData.totalAmount,
      interestAmount: formData.interestAmount,
      days: formData.days
    };

    // Send a DELETE request to the API endpoint
    axios.delete('https://103.50.212.98:4431/api/FdDetails/delete', { data: deleteData })
      .then(response => {
        console.log('Delete API response:', response.data);
        // Optionally, perform any additional actions after successful delete
        // For example, close the modal or refresh data
        alert("deleted successfully")
        handleResetForm(); // Reset form fields after successful deletion

      })
      .catch(error => {
        console.error('Error deleting FD:', error);
        // Handle delete error here
      });
  };


  const handleResetForm = () => {
    // Reset all form fields to empty values
    handleInputChange({ target: { name: 'fdId', value: '' } });
    handleInputChange({ target: { name: 'fdDate', value: '' } });
    handleInputChange({ target: { name: 'fdCloseDate', value: '' } });
    handleInputChange({ target: { name: 'days', value: '' } });
    handleInputChange({ target: { name: 'fdAmount', value: '' } });
    handleInputChange({ target: { name: 'totalAmount', value: '' } });
    handleInputChange({ target: { name: 'chequeNo', value: '' } });
    handleInputChange({ target: { name: 'reciptNo', value: '' } });
  };

 

  return (
    <div>
      <i onClick={handleEdit} type="button" className='material-icons edit-icon' data-toggle="modal" data-target="#exampleModal">edit_sweep</i>



      {/* <!-- Modal --> */}
      <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Close FD</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">




              {/* modal form start */}
              {/* {fdDetails ? ( */}
              <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="card modalFormCard">
                    <div className="header">
                      <h2>Close FD</h2>
                    </div>
                    <div className="body">
                      <div className="demo-masked-input">
                        <div className="row clearfix">



                          <div className="col-md-8">
                            <b>ID</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  className="form-control date"
                                  placeholder="Enter id"
                                  value={formData.fdId}
                                  onChange={(e) => handleInputChange(e)}
                                  name="fdId" id="fdId"
                                />
                              </div>
                            </div>
                          </div>



                          <div className="col-md-8">
                            <b>Start Date</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">date_range</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="date"
                                  className="form-control date"
                                  placeholder="Select Start Date"
                                  value={formData.fdDate ? formData.fdDate.split('T')[0] : ''}
                                  readOnly
                                  name="fdDate" id="fdDate"
                                  onChange={(e) => handleInputChange(e)}

                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-8">
                            <b>End Date</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">date_range</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="date"
                                  className="form-control date"
                                  placeholder="Select End Date"
                                  value={formData.fdCloseDate ? formData.fdCloseDate.split('T')[0] : ''}
                                  // readOnly
                                  onChange={(e) => handleInputChange(e)}
                                  name="fdCloseDate" id="fdCloseDate"

                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-8">
                            <b>Total Days</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  className="form-control date"
                                  placeholder='Enter total days'
                                  value={formData.days}
                                  onChange={(e) => handleInputChange(e)}
                                  name="days" id="days"

                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-8">
                            <b>FD Amount</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  className="form-control date"
                                  placeholder='Enter FD amount'
                                  value={formData.fdAmount}
                                  onChange={(e) => handleInputChange(e)}
                                  name="fdAmount" id="fdAmount"
                                />
                              </div>
                            </div>
                          </div>

                         

                          <div className="col-md-8">
                            <b>Total Amount</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  className="form-control date"
                                  placeholder='Enter Total amount'
                                  value={formData.totalAmount}
                                  onChange={(e) => handleInputChange(e)}
                                  name="totalAmount" id="totalAmount"

                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-8">
                            <b>cheque Number</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  className="form-control date"
                                  placeholder='Enter cheque number'
                                  value={formData.chequeNo}
                                  onChange={(e) => handleInputChange(e)}
                                  name="chequeNo" id="chequeNo"

                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-8">
                            <b>Receipt Number</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  className="form-control date"
                                  placeholder='Enter Reciept number'
                                  value={formData.reciptNo}
                                  onChange={(e) => handleInputChange(e)}
                                  name="reciptNo" id="reciptNo"

                                />
                              </div>
                            </div>
                          </div>




                          <button type="button" onClick={handleDelete} className="btn bg-indigo btn-block btn-lg waves-effect" >Submit</button>
                          <button type="button" className="btn bg-indigo btn-block btn-lg waves-effect closeButton" data-dismiss="modal">Close</button>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ) : null} */}
              {/* Modal form ends */}


            </div>
          </div>
        </div>
      </div>
      {/* Modal End */}
    </div>
  )
}

export default CloseFDmodal

