import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import AdminSideBar from '../../AdminSideBar/AdminSideBar'
import AdminTopBar from '../../AdminTopBar/AdminTopBar'
import './MonthlyEMIChange.css'
import { Button, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import axios from 'axios'

const MonthlyEMIChange = () => {
  const { handleSubmit, register, reset, formState: { errors } } = useForm();
  const [userData, setUserData] = useState(null); // State to store user data
  const [accountNo, setAccountNo] = useState(''); // State to store account number

  const onSubmit = async (data) => {
    try {
      const response = await axios.put('https://103.50.212.98:4431/api/ChangeEMI', data);
      console.log('Response status:', response.status);
      // Reset form fields after successful submission
      reset();
    } catch (error) {
      console.error('Error:', error);
      // Handle error here
    }
  };
  // Update accountNo state when input changes
  const handleAccountNoChange = (event) => {
    setAccountNo(event.target.value);
  };

  // Function to fetch user details based on account number
  const fetchUserDetails = async (accountNo) => {
    try {
      const res = await axios.get(`https://103.50.212.98:4431/api/ChangeEMI/AccountNo?AccountNo=${accountNo}`);
      const userData = res.data.result;
      console.log(userData);
      setUserData(userData);
    } catch (error) {
      console.log(error);
    }
  };


  // Prevent accidental value changes on scroll
  const handleScroll = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div>
      <div className="theme-red">
        {/* <!-- Page Loader --> */}
        {/* <Loader /> */}
        {/* <!-- #END# Page Loader -->
   
   
   
   
   
  <!-- Search Bar --> */}
        <div className="search-bar">
          <div className="search-icon">
            <i className="material-icons">search</i>
          </div>
          <input type="text" placeholder="START TYPING..." />
          <div className="close-search">
            <i className="material-icons">close</i>
          </div>

        </div>
        {/* <!-- #END# Search Bar -->
   
   
   
   
  <!-- Top Bar --> */}
        <AdminTopBar />
        {/* <!-- #Top Bar --> */}



        {/* sideBar */}


        <AdminSideBar />
        {/* end sideBar */}

        <section className="content" onScroll={handleScroll}>
          <Link to="/">  <h4 >DASHBOARD</h4></Link>
          <div className="container-fluid">
            <div className="block-header">

            </div>







            {/* form starts */}
            {/* <!-- Masked Input --> */}







            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2>Monthly EMI Change</h2>
                  </div>
                  <div className="body">
                    <div className="demo-masked-input">
                      <div className="row clearfix">


                        <Form onSubmit={handleSubmit(onSubmit)}>


                          <div className="col-md-8">
                            <b>Account Number (खाते क्रमांक)</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <div className="col-4 SearchInputField">
                                  <input
                                    type="number"
                                    name='accountNo'
                                    className={`form-control date ${errors.accountNo ? 'is-invalid' : ''}`}
                                    placeholder="Enter Account Number"
                                    {...register("accountNo")}
                                    onChange={handleAccountNoChange}

                                  />
                                  <button type="button"
                                    onClick={() => fetchUserDetails(accountNo)}
                                    className="btn bg-indigo btn-block btn-lg waves-effect primaryButton secondaryButton ">Search</button>
                                </div>
                                {errors.accountNo && errors.accountNo.type === "pattern" && (
                                  <div className="invalid-feedback text-red">Please enter only numbers</div>
                                )}
                              </div>
                            </div>
                          </div>

                          {/* <button type="button" className="btn btn-primary ">Search</button> */}




                          <div className="col-md-8">
                            <b>Full Name (पूर्ण नाव)</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="text"
                                  className="form-control date"
                                  placeholder="Enter Full Name (पूर्ण नाव)"
                                  value={userData ? userData.fullName : ''}
                                  readOnly // Make this field read-only
                                  {...register("fullName")}
                               
                               />
                              </div>
                            </div>
                          </div>




                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <b>Current Status (Deduction)</b> <br /> <br />
                            <b>Shares</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="text"
                                  className="form-control date"
                                  placeholder="Enter Current Status Shares"
                                  value={userData ? userData.shares : ''}
                                  readOnly // Make this field read-only
                                

                               />

                              </div>
                            </div>
                          </div>


                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <b>Update Amount (Deduction)</b>  <br />   <br />  <br />
                            {/* <b>Full Name (पूर्ण नाव)</b> */}
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="text"
                                  className="form-control date"
                                  placeholder="Enter Update Amount Shares"
                                  {...register("shares")}

                               />

                              </div>
                            </div>
                          </div>






                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">

                            <b>Monthly Deposit</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  className="form-control date"
                                  placeholder="Enter Current Monthly Deposite"
                                  value={userData ? userData.monthlyDeposit : ''}
                                  readOnly // Make this field read-only
                                />

                              </div>
                            </div>
                          </div>


                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">

                            {/* <b>Full Name (पूर्ण नाव)</b> */} <br />
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  className="form-control date"
                                  placeholder="Enter Update Amount Monthly Deposite"
                                  {...register("monthlyDeposit")}

                               />

                              </div>
                            </div>
                          </div>





                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">

                            <b>Insurance</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  className="form-control date"
                                  placeholder="Enter Current Status Insurence"
                                  value={userData ? userData.insurance : ''}
                                  readOnly // Make this field read-only
                                />

                              </div>
                            </div>
                          </div>


                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">

                            {/* <b>Full Name (पूर्ण नाव)</b> */} <br />
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  className="form-control date"
                                  placeholder="Enter Update Amount Insurence"
                                  {...register("insurance")}

                                />

                              </div>
                            </div>
                          </div>







                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">

                            <b>Regular Loan</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  className="form-control date"
                                  placeholder="Enter Current Status Regular Loan"
                                  value={userData ? userData.regLoan : ''}
                                  readOnly // Make this field read-only
                                />

                              </div>
                            </div>
                          </div>


                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">

                            {/* <b>Full Name (पूर्ण नाव)</b> */} <br />
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  className="form-control date"
                                  placeholder="Enter Update Amount Regular Loan"
                                  {...register("regLoan")}

                                />

                              </div>
                            </div>
                          </div>





                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">

                            <b>Emergency Loan</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  className="form-control date"
                                  placeholder="Enter Current Status Emergency Loan"
                                  value={userData ? userData.emgLoan : ''}
                                  readOnly // Make this field read-only
                                />

                              </div>
                            </div>
                          </div>


                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">

                            {/* <b>Full Name (पूर्ण नाव)</b> */} <br />
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  className="form-control date"
                                  placeholder="Enter Update Amount Emergency Loan"
                                  {...register("emgLoan")}

                               />

                              </div>
                            </div>
                          </div>





                          <div className="col-md-8">
                            <b>Date</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="date"
                                  className="form-control date"
                                  {...register("changeDate")}

                                // value={userData ? userData.changeDate : ''}
                                // readOnly // Make this field read-only
                                />
                              </div>
                            </div>
                            <Button type="submit" variant="primary" className="btn btn-block btn-lg waves-effect">Submit</Button>
                          </div>









                        </Form>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- #END# Masked Input --> */}
            {/* form starts */}










          </div>
        </section>
      </div>
    </div>
  )
}

export default MonthlyEMIChange
