import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AdminSideBar from '../../AdminSideBar/AdminSideBar'
import AdminTopBar from '../../AdminTopBar/AdminTopBar'
import { useForm } from 'react-hook-form'
import { Submit } from '../../Service';
import { Button, Form } from 'react-bootstrap'
import axios from 'axios'
import EmergencyLoanUserD from './EmergencyLoanUserD'



const EmergencyLoan = () => {


  const { register, reset, formState: { errors } } = useForm();
  const [users, setUsers] = useState([])
  const [userData, setUserData] = useState(null); // State to store user data
  const [accountNo, setAccountNo] = useState(''); // State to store account number
  const [chequeNoError, setChequeNoError] = useState(false);
  const [DateError, setFdDateError] = useState(false);

  const [rateOfInterest, setRateOfInterest] = useState('');
  const [formData, setFormData] = useState({
    accountNo: '',
    loanAmount: '',
    rateOfInterest: '',
    date: '',
    chequeNo: '',
    // principleAmount: '',
    // netSalary: '',
    // loanBalanceAmount: '',
    // totalPrincipalAmount: '',
    // rateOfInterest: '',

    // date: '',
    // principleAmount: '',
    // interestAoumnt: 0,
    // totalFdamount: 0,
  });

  useEffect(() => {
    const fetchrateOfInterest = async () => {
      try {
        const response = await axios.get('https://103.50.212.98:4431/api/EmergencyLoan/getInterestRate');
        const { result } = response.data;
        setRateOfInterest(result);
      } catch (error) {
        console.log('Error fetching FD Certificate Number:', error);
      }
    };

    fetchrateOfInterest(); // Trigger the API call when component mounts
  }, []); // Empty dependency array means this effect runs only once after the initial render

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }
  const handlerateOfInterest = (e) => {
    const { value } = e.target;
    setRateOfInterest(value); // Update the fdCertificateNo state directly
  };

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if FD amount is empty
    if (!formData.chequeNo) {
      setChequeNoError(true);
    } else {
      setChequeNoError(false);
    }

    // Check if FD date is empty
    if (!formData.date) {
      setFdDateError(true);
    } else {
      setFdDateError(false);
    }

    // If either FD amount or FD date is empty, exit the function
    if (!formData.chequeNo || !formData.date) {
      return;
    }

    try {
      // Add fdCertificateNo to the formData object
      const formDataWithrateOfInterest = {
        ...formData,
        rateOfInterest: rateOfInterest,
      };

      const response = await axios.post('https://103.50.212.98:4431/api/EmergencyLoan/post', formDataWithrateOfInterest);
      console.log('Form data submitted successfully:', response.data);
      alert("Form data submitted successfully")
      // Handle success or navigate to another page
      // Reset the form fields to initial empty state
      setFormData({
        accountNo: '',
        loanAmount: '',
        rateOfInterest: '',
        date: '',
        chequeNo: '',
      });
    } catch (error) {
      console.error('Error submitting form data:', error);
      // Handle error state or show error message
    }
  };

  const fetchUsers = async (url) => {
    try {
      const res = await axios.get(url); // Use axios.get instead of fetch
      const Tdata = res.data; // Axios response data is directly accessible as res.data
      console.log(Tdata, "my table data"); // Log the data here to inspect its structure
      setUsers(Tdata); // Set the fetched data to the users state
    } catch (error) {
      console.log(error);
    }
  };

  const API = "https://103.50.212.98:4431/api/EmergencyLoan/get";

  useEffect(() => {
    fetchUsers(API);
  }, []);

  // Update accountNo state when input changes
  const handleAccountNoChange = (event) => {
    setAccountNo(event.target.value);
  };


  // Function to fetch user details based on account number
  const fetchUserDetails = async (accountNo) => {
    try {
      const res = await axios.get(`http://103.50.212.98:8084/api/EmergencyLoan/getUserDetailsByAccountNo?accountNo=${accountNo}`);
      const userData = res.data.result;
      setUserData(userData);
      // Update accountName based on fetched user data
      setFormData({
        ...formData,
        fullName: userData.fullName,
        principleAmount: userData.principleAmount,
        loanBalanceAmount: userData.loanBalanceAmount,
        // share: userData.share,
        // principleAmount: userData.principleAmount,

      });
    } catch (error) {
      console.log(error);
    }
  };


  // Prevent accidental value changes on scroll
  const handleScroll = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <div>
      <div className="theme-red">
        {/* <!-- Page Loader --> */}
        {/* <Loader /> */}
        {/* <!-- #END# Page Loader -->
   
   
   
   
   
  <!-- Search Bar --> */}
        <div className="search-bar">
          <div className="search-icon">
            <i className="material-icons">search</i>
          </div>
          <input type="text" placeholder="START TYPING..." />
          <div className="close-search">
            <i className="material-icons">close</i>
          </div>

        </div>
        {/* <!-- #END# Search Bar -->
   
   
   
   
  <!-- Top Bar --> */}
        <AdminTopBar />
        {/* <!-- #Top Bar --> */}



        {/* sideBar */}


        <AdminSideBar />
        {/* end sideBar */}

        <section className="content" onScroll={handleScroll}>
          <Link to="/">  <h4 >DASHBOARD</h4></Link>
          <div className="container-fluid">
            <div className="block-header">

            </div>



            {/* form starts */}
            {/* <!-- Masked Input --> */}


            <div className="row clearfix">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2>Emergency Loan</h2>
                  </div>
                  <div className="body">
                    <div className="demo-masked-input">
                      <div className="row clearfix">
                        <Form onSubmit={handleSubmit}>



                          <div className="col-md-8">
                            <b>Account Number (खाते क्रमांक)</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                {/* <input
                                  type="text"
                                  name='accountNo'
                                  className={`form-control date ${errors.accountNo ? 'is-invalid' : ''}`}
                                  placeholder="Enter Account Number"
                                  {...register("accountNo", { pattern: /^[0-9]*$/ })}
                                  onChange={handleAccountNoChange}

                                /> */}
                                <input
                                  type="number"
                                  name='accountNo'
                                  className="form-control date"
                                  placeholder="Enter Account Number"
                                  value={formData.accountNo}
                                  onChange={handleInputChange}
                                />
                                <button
                                  type="button"
                                  onClick={() => fetchUserDetails(formData.accountNo)}
                                  className="btn bg-indigo btn-block btn-lg waves-effect primaryButton secondaryButton"
                                >
                                  Search
                                </button>
                              </div>
                              {errors.accountNo && errors.accountNo.type === "pattern" && (
                                <div className="invalid-feedback text-red">Please enter only numbers</div>
                              )}
                            </div>
                          </div>


                          {/* <button type="button" class="btn btn-primary ">Search</button> */}



                          <div className="col-md-8">
                            <b>Full Name (पूर्ण नाव)</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="text"
                                  name='fullName'
                                  className="form-control date"
                                  placeholder="Enter Full Name"

                                  value={formData.fullName}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>


                          <div className="col-md-8">
                            <b>Loan Amount</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                              <input
                                    type="number"
                                    name='loanAmount'
                                    className="form-control date"
                                    placeholder="Enter Loan Balance"
                                    value={formData.loanAmount}
                                    onChange={handleInputChange}
                                  />
                              </div>
                            </div>
                          </div>



                          <div className="col-md-8">
                            <b>ROI (व्याज दर)</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="text"
                                  name='rateOfInterest'
                                  className="form-control date"
                                  placeholder="ROI"
                                  value={rateOfInterest} // Set the value to the fetched ROI
                                  onChange={handlerateOfInterest}
                                  readOnly // Set the input as read-only
                                // {...register("rateOfInterest")}
                                />
                              </div>
                            </div>
                          </div>



                          <div className="col-md-8">
                            <b>Date</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="date"
                                  name='date'
                                  // className="form-control "
                                  className={`form-control ${DateError ? 'is-invalid' : ''}`}


                                  value={formData.date}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                            {DateError && <p style={{ color: "red" }}>Date is required</p>}
                            {/* <p style={{ color: "red" }}>{errors?.fdAmount?.message}</p> */}

                          </div>



                          <div className="col-md-8">
                            <b>User Principal Amount</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  name='principleAmount'
                                  className="form-control date"
                                  placeholder="Enter Principal Amount"
                                  value={formData.principleAmount}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                          </div>



                          {/* <div className="col-md-8">
                            <b>EMI</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <Form.Group className="mb-3" controlId="PrevLoanAmountField">
                                  <Form.Control
                                    type="number"
                                    name='emi'
                                    className="form-control date"
                                    placeholder="Enter Prev. Loan Amount (मागील कर्जाची रक्कम)"
                                    {...register("emi", { required: "please enter Amount" })}

                                  />
                                </Form.Group>
                              </div>
                            </div>
                            <p style={{ color: "red" }}>{errors?.emi?.message}</p>
                          </div> */}



                          {/* <div className="col-md-8">
                            <b>Total Principal Amount</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <Form.Group className="mb-3" controlId="creditLoanAmountField">
                                  <Form.Control
                                    type="number"
                                    name='totalPrincipalAmount'
                                    className="form-control date"
                                    value={formData.totalPrincipalAmount}

                                    placeholder="Enter Credit Loan Amount (कर्जाची रक्कम)"
                                  // {...register("totalPrincipalAmount", { required: "please enter Credit Loan Amount" })}
                                  />
                                </Form.Group>
                              </div>
                            </div>
                            <p style={{ color: "red" }}>{errors?.totalPrincipalAmount?.message}</p>
                          </div> */}


                          {/* <div className="col-md-8">
                            <b>Interest</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  name='interest'
                                  className="form-control date"
                                  placeholder="Enter Loan Balance After Deduction"
                                  {...register("interest")}
                                />
                                <button type="button" className="btn bg-indigo btn-block btn-lg waves-effect primaryButton secondaryButton">Check</button>
                              </div>
                            </div>
                          </div> */}

                          {/* <button type="button" className="btn btn-primary loanAfterDeduction_btn">Check</button> */}



                          <div className="col-md-8">
                            <b> Loan Balance Amount</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <Form.Group className="mb-3" controlId="chequeNumberField">
                                  <Form.Control
                                    type="number"
                                    name='loanBalanceAmount'
                                    className="form-control date"
                                    value={formData.loanBalanceAmount}
                                    // onChange={handleInputChange}

                                    placeholder="Enter loan balance amount"
                                    // {...register("emgLoanBal", { required: "please enter Cheque Number" })}
                                    readOnly
                                  />
                                </Form.Group>
                              </div>
                            </div>
                            <p style={{ color: "red" }}>{errors?.emgLoanBal?.message}</p>
                          </div>



                          <div className="col-md-8">
                            <b>Cheque No/Recipt No</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  name='chequeNo'
                                  // className="form-control "
                                  className={`form-control ${chequeNoError ? 'is-invalid' : ''}`}


                                  value={formData.chequeNo}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                            {chequeNoError && <p style={{ color: "red" }}>Cheque number is required</p>}

                          </div>



                          {/* <div className="col-md-8">
                          <b>Full Name (पूर्ण नाव)</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                            
                              <div className="col-8">
                                        <input className="form-control" type="file" id="photo" name="photo" multiple=""/>
                                    </div>
                            </div>
                          </div>
                        </div>  */}


                          <Button type="submit" variant='primary' className="btn bg-indigo btn-block btn-lg waves-effect">Submit</Button>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- #END# Masked Input --> */}
            {/* form starts */}



            {/* Table start */}

            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2>Emergency Loan Member List</h2>

                  </div>
                  <div className="body">
                    <div className="table-responsive">
                      <div
                        id="DataTables_Table_1_wrapper"
                        className="dataTables_wrapper form-inline dt-bootstrap"
                      >


                        {/* <label>Show <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="form-control input-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option></select> entries</label> */}

                        <table
                          className="table table-bordered table-striped table-hover dataTable js-exportable"
                          id="DataTables_Table_1"
                          role="grid"
                          aria-describedby="DataTables_Table_1_info"
                        >

                          <tbody>

                            <EmergencyLoanUserD Users={users} />


                          </tbody>
                        </table>
                        <div
                          className="dataTables_info"
                          id="DataTables_Table_1_info"
                          role="status"
                          aria-live="polite"
                        >
                          Showing 1 to 10 of 57 entries
                        </div>
                        <div
                          className="dataTables_paginate paging_simple_numbers"
                          id="DataTables_Table_1_paginate"
                        >
                          <ul className="pagination">
                            <li
                              className="paginate_button previous disabled"
                              id="DataTables_Table_1_previous"
                            >
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="0"
                                tabIndex="0"
                              >
                                Previous
                              </a>
                            </li>
                            <li className="paginate_button active">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="1"
                                tabIndex="0"
                              >
                                1
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="2"
                                tabIndex="0"
                              >
                                2
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="3"
                                tabIndex="0"
                              >
                                3
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="4"
                                tabIndex="0"
                              >
                                4
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="5"
                                tabIndex="0"
                              >
                                5
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="6"
                                tabIndex="0"
                              >
                                6
                              </a>
                            </li>
                            <li
                              className="paginate_button next"
                              id="DataTables_Table_1_next"
                            >
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="7"
                                tabIndex="0"
                              >
                                Next
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  Table end */}

          </div>
        </section>
      </div>
    </div>
  )
}

export default EmergencyLoan
