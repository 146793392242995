import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import AdminSideBar from '../../AdminSideBar/AdminSideBar'
import AdminTopBar from '../../AdminTopBar/AdminTopBar'

const MonthlyEmiEdit = () => {
  return (
    <div>
    <div className="theme-red">
     {/* <!-- Page Loader --> */}
     {/* <Loader /> */}
     {/* <!-- #END# Page Loader -->





<!-- Search Bar --> */}
     <div className="search-bar">
       <div className="search-icon">
         <i className="material-icons">search</i>
       </div>
       <input type="text" placeholder="START TYPING..." />
       <div className="close-search">
         <i className="material-icons">close</i>
       </div>
    
     </div>
     {/* <!-- #END# Search Bar -->




<!-- Top Bar --> */}
    <AdminTopBar/>
     {/* <!-- #Top Bar --> */}


     
{/* sideBar */}


<AdminSideBar/>
{/* end sideBar */}

     <section className="content">
          <Link to="/">  <h4 >DASHBOARD</h4></Link>
       <div className="container-fluid">
         <div className="block-header">
         
         </div>






       
           {/* form starts */}
         {/* <!-- Masked Input --> */}



                          



         <div className="row clearfix">
           <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
             <div className="card">
               <div className="header">
                 <h2>Monthly EMI Edit</h2>
               </div>
               <div className="body">
                 <div className="demo-masked-input">
                   <div className="row clearfix">
                    
                    
                  

                      <div className="col-md-8">
                       <b>Account Number (खाते क्रमांक)</b>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                                 <div className="col-4 SearchInputField">
                                     <input className="form-control date " type="number" placeholder="Enter Account Number" />
                                     <button type="button" className="btn bg-indigo btn-block btn-lg waves-effect primaryButton secondaryButton ">Search</button>
                                </div>
                          </div>
                       </div>
                     </div> 

                     {/* <button type="button" className="btn btn-primary ">Search</button> */}
                 



                      




                     <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                       <b>Current Status (Deduction)</b> <br/> <br/>
                       <b>Shares</b>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="number"
                             className="form-control date"
                             placeholder="Enter Current Status Shares"
                           />
                           
                         </div>
                       </div>
                     </div> 


                     <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                   <b>Update Amount (Deduction)</b>  <br/>   <br/>  <br/>
                       {/* <b>Full Name (पूर्ण नाव)</b> */}
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="number"
                             className="form-control date"
                             placeholder="Enter Update Amount Shares"
                           />
                           
                         </div>
                       </div>
                     </div> 






                     <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                       
                       <b>Monthly Deposit</b>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="number"
                             className="form-control date"
                             placeholder="Enter Current Monthly Deposite"
                           />
                           
                         </div>
                       </div>
                     </div> 


                     <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                     
                       {/* <b>Full Name (पूर्ण नाव)</b> */} <br/>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="number"
                             className="form-control date"
                             placeholder="Enter Update Amount Monthly Deposite"
                           />
                           
                         </div>
                       </div>
                     </div> 





                     <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                       
                       <b>Insurance</b>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="number"
                             className="form-control date"
                             placeholder="Enter Current Status Insurence"
                           />
                           
                         </div>
                       </div>
                     </div> 


                     <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                     
                       {/* <b>Full Name (पूर्ण नाव)</b> */} <br/>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="number"
                             className="form-control date"
                             placeholder="Enter Update Amount Insurence"
                           />
                           
                         </div>
                       </div>
                     </div> 







                     <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                       
                       <b>Regular Loan</b>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="number"
                             className="form-control date"
                             placeholder="Enter Current Status Regular Loan"
                           />
                           
                         </div>
                       </div>
                     </div> 


                     <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                     
                       {/* <b>Full Name (पूर्ण नाव)</b> */} <br/>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="number"
                             className="form-control date"
                             placeholder="Enter Update Amount Regular Loan"
                           />
                           
                         </div>
                       </div>
                     </div> 




                     <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                       
                       <b>Regular Loan Interest</b>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="number"
                             className="form-control date"
                             placeholder="Enter Current Status Regular Loan"
                           />
                           
                         </div>
                       </div>
                     </div> 


                     <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                     
                       {/* <b>Full Name (पूर्ण नाव)</b> */} <br/>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="number"
                             className="form-control date"
                             placeholder="Enter Update Amount Regular Loan"
                           />
                           
                         </div>
                       </div>
                     </div> 





                     <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                       
                       <b>Emergency Loan</b>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="number"
                             className="form-control date"
                             placeholder="Enter Current Status Emergency Loan"
                           />
                           
                         </div>
                       </div>
                     </div> 


                     <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                     
                       {/* <b>Full Name (पूर्ण नाव)</b> */} <br/>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="number"
                             className="form-control date"
                             placeholder="Enter Update Amount Emergency Loan"
                           />
                           
                         </div>
                       </div>
                     </div> 


                     <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                       
                       <b>Emergency Loan Interest</b>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="number"
                             className="form-control date"
                             placeholder="Enter Current Status Regular Loan"
                           />
                           
                         </div>
                       </div>
                     </div> 


                     <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                     
                       {/* <b>Full Name (पूर्ण नाव)</b> */} <br/>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="number"
                             className="form-control date"
                             placeholder="Enter Update Amount Regular Loan"
                           />
                           
                         </div>
                       </div>
                     </div> 










                     <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                       
                       <b>RD</b>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="number"
                             className="form-control date"
                             placeholder="Enter Current Status Regular Loan"
                           />
                           
                         </div>
                       </div>
                     </div> 


                     <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                     
                       {/* <b>Full Name (पूर्ण नाव)</b> */} <br/>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="number"
                             className="form-control date"
                             placeholder="Enter Update Amount Regular Loan"
                           />
                           
                         </div>
                       </div>
                     </div> 









                     <div className="col-md-8">
                       <b>Date</b>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="date"
                             className="form-control date"
                            
                           />
                         </div>
                       </div>
                     </div> 


                  
                    


                    

                               
                   </div>
                   <Button type="submit"  variant='primary' className="btn btn-block btn-lg waves-effect">Submit</Button>

                 </div>
               </div>
             </div>
           </div>
         </div>
         {/* <!-- #END# Masked Input --> */}
         {/* form starts */}










       </div>
     </section>
   </div>
 </div>
  )
}

export default MonthlyEmiEdit
