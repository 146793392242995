import { React, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AdminSideBar from '../../AdminSideBar/AdminSideBar'
import AdminTopBar from '../../AdminTopBar/AdminTopBar'
import './AddAccountHead.css'
import AddAccountHeadModal from './AddAccountHeadModal'
import { Submit } from '../../Service'
import { Button, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import UserData from './UserData.js'


const AddAccountHead = () => {
  const { handleSubmit, register, reset, formState: { errors } } = useForm();
  // const [tableData, setTableData] = useState([]);
  const [users, setUsers] = useState([])

  const onSubmit = (data) => {
    console.log("data", data);

    axios.post('https://103.50.212.98:4431/api/HeadType/post', data)

      .then((response) => {
        console.log('ye Response:', response.data);
        // Handle success response here
        console.log(data);
        reset();
      })
      .catch((error) => {
        console.error('Error:', error);
        // Handle error response here
      });

  };
  
  const fetchUsers = async (url) => {
    try {
      const res = await fetch(url);
      const Tdata = await res.json();
      console.log(Tdata, "my table data"); // Log the data here to inspect its structure
      setUsers(Tdata); // Set the fetched data to the users state
    } catch (error) {
      console.log(error);
    }
  };




  const API = "https://103.50.212.98:4431/api/HeadType/get"
  useEffect(() => {
    fetchUsers(API)

  }, [])

  // const onSubmit = async (data) => {
  //   console.log("data", data);
  //   try {
  //     // const response = await axios.post('/api/DeadMember', data);
  //     const response = await axios.post('https://103.50.212.98/api/HeadType/post', data);

  //     console.log('Response status:', response.data); // Logging only the status
  //     // Handle success response here
  //     reset();
  //     alert('Form Submitted successfully');
  //     // const tableDataResponse = await axios.get('https://103.50.212.98/api/HeadType/get');
  //     // setTableData(tableDataResponse.data.data);

  //   } catch (error) {
  //     console.error('Error:', error);
  //     // Handle error response here
  //   }

  // };
  // const fetchUsers = async (url) => {
  //   try {
  //     const res = await fetch(url);
  //     // setUsers(res.data)
  //     const Tdata = await res.json();
  //     console.log(Tdata, "my table data"); // Log the data here to inspect its structure
  //     setUsers(Tdata); // Set the fetched data to the users state
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };




  // const API = "https://103.50.212.98/api/HeadType/get"
  // useEffect(() => {
  //   fetchUsers(API)

  // }, [])
  return (
    <div>
      <div className="theme-red">
        {/* <!-- Page Loader --> */}
        {/* <Loader /> */}
        {/* <!-- #END# Page Loader -->

  <!-- Search Bar --> */}
        <div className="search-bar">
          <div className="search-icon">
            <i className="material-icons">search</i>
          </div>
          <input type="text" placeholder="START TYPING..." />
          <div className="close-search">
            <i className="material-icons">close</i>
          </div>

        </div>
        {/* <!-- #END# Search Bar -->
 
  <!-- Top Bar --> */}
        <AdminTopBar />
        {/* <!-- #Top Bar --> */}

        {/* sideBar */}

        <AdminSideBar />
        {/* end sideBar */}

        <section className="content">
          <Link to="/">  <h4 >DASHBOARD</h4></Link>
          <div className="container-fluid">
            <div className="block-header">

            </div>
            {/* form starts */}
            {/* <!-- Masked Input --> */}
            <div className="row clearfix">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2>Add Account Head</h2>
                  </div>
                  <div className="body">
                    <div className="demo-masked-input">
                      <div className="row clearfix">



                        {/* <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className="col-md-8">
                            <b>Add Account Head</b>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="material-icons">event_note</i>
                                </span>
                                <div className="form-line">
                                  <Form.Control
                                    type="text"
                                    name='headType'
                                    className="form-control time24"
                                    placeholder="Enter Party Name"
                                    {...register("headType", { required: "please enter add Account head name" })}
                                  />
                                </div>
                              </div>
                            </Form.Group>
                            <p style={{ color: "red" }}>{errors?.headType?.message}</p>
                            <Button type="submit" variant='primary' className="btn btn-block btn-lg waves-effect">Submit</Button>
                        </div>
                          </Form> */}

                        <Form onSubmit={handleSubmit(onSubmit)}>
                          <div className="col-md-8">
                            <b>Head Type</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <Form.Group className="mb-3" controlId="change">
                                  <Form.Control
                                    type="text"
                                    name='headType'
                                    className="form-control date"
                                    placeholder="Enter Head Type"
                                    {...register("headType", { required: "please enter value" })}
                                  />
                                </Form.Group>
                              </div>
                            </div>
                            <p style={{ color: "red" }}>{errors?.headType?.message}</p>
                          </div>



                          {/* <div className="col-md-8">
                            <b>Rate of Interest</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span> */}
                          {/* <div className="form-line">
                                <input
                                  type="number"
                                  name='rateOfInterest'
                                  className="form-control date"
                                  placeholder='Enter Rate of Interest'
                                  {...register("rateOfInterest")}

                                />
                              </div> */}
                          {/* </div> */}
                          {/* </div> */}


                          <Button type="submit" variant='primary' className="btn btn-block btn-lg waves-effect">Submit</Button>
                        </Form>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- #END# Masked Input --> */}
            {/* form starts */}

            {/* Table start */}

            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2>View Party Head</h2>

                  </div>
                  <div className="body">
                    <div className="table-responsive">
                      <div
                        id="DataTables_Table_1_wrapper"
                        className="dataTables_wrapper form-inline dt-bootstrap"
                      >
                      
                       

                        {/* <label>Show <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="form-control input-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option></select> entries</label> */}

                        <table
                          className="table table-bordered table-striped table-hover dataTable js-exportable"
                          id="DataTables_Table_1"
                          role="grid"
                          aria-describedby="DataTables_Table_1_info"
                        >
                        
                                                   <tbody>
                          <UserData Users={users} />

                           
                          </tbody>
                        </table>
                        <div
                          className="dataTables_info"
                          id="DataTables_Table_1_info"
                          role="status"
                          aria-live="polite"
                        >
                          Showing 1 to 10 of 57 entries
                        </div>
                        <div
                          className="dataTables_paginate paging_simple_numbers"
                          id="DataTables_Table_1_paginate"
                        >
                          <ul className="pagination">
                            <li
                              className="paginate_button previous disabled"
                              id="DataTables_Table_1_previous"
                            >
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="0"
                                tabIndex="0"
                              >
                                Previous
                              </a>
                            </li>
                            <li className="paginate_button active">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="1"
                                tabIndex="0"
                              >
                                1
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="2"
                                tabIndex="0"
                              >
                                2
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="3"
                                tabIndex="0"
                              >
                                3
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="4"
                                tabIndex="0"
                              >
                                4
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="5"
                                tabIndex="0"
                              >
                                5
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="6"
                                tabIndex="0"
                              >
                                6
                              </a>
                            </li>
                            <li
                              className="paginate_button next"
                              id="DataTables_Table_1_next"
                            >
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="7"
                                tabIndex="0"
                              >
                                Next
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  Table end */}
          </div>
        </section>
      </div>

    </div>
  )
}

export default AddAccountHead
