import axios from 'axios';
import React from 'react'

const CloseRDmodal = ({ handleEdit ,handleInputChange,formData}) => {
  const handleDelete = () => {
    // Prepare the data for the delete request
    const deleteData = {
      rdid: formData.rdid,
      accountNo: formData.accountNo, // Assuming fdYear is equivalent to fdDate
      fullName: formData.fullName,
      rdno: formData.rdno,
      rdmonthlyAmount: formData.rdmonthlyAmount,
      startDate: formData.startDate,
      receiptNo: formData.receiptNo,
      changeInterestId: formData.changeInterestId,
      endDate: formData.endDate,
      chequeNumber: formData.chequeNumber,
       isDelete: true,
      previosBalance: formData.previosBalance,
      totalAmount: formData.totalAmount,
      interestAmount: formData.interestAmount,
      interestId: formData.interestId,
      modifiedDate: formData.modifiedDate,
      note:formData.note,
      days: formData.days
    };

    // Send a DELETE request to the API endpoint
    axios.delete('https://103.50.212.98:4431/api/RdDetails/delete', { data: deleteData })
      .then(response => {
        console.log('Delete API response:', response.data);
        // Optionally, perform any additional actions after successful delete
        // For example, close the modal or refresh data
        alert("deleted successfully")
        handleResetForm(); // Reset form fields after successful deletion

      })
      .catch(error => {
        console.error('Error deleting FD:', error);
        // Handle delete error here
      });
  };


  const handleResetForm = () => {
    // Reset all form fields to empty values
    handleInputChange({ target: { name: 'rdid', value: '' } });
    handleInputChange({ target: { name: 'startDate', value: '' } });
    handleInputChange({ target: { name: 'endDate', value: '' } });
    handleInputChange({ target: { name: 'days', value: '' } });
    handleInputChange({ target: { name: 'rdmonthlyAmount', value: '' } });
    handleInputChange({ target: { name: 'totalAmount', value: '' } });
    handleInputChange({ target: { name: 'chequeNumber', value: '' } });
    handleInputChange({ target: { name: 'receiptNo', value: '' } });
  };

 
  return (
    <div>
    <i  type="button" onClick={handleEdit} className='material-icons delete-icon' data-toggle="modal" data-target="#exampleModal">delete_sweep</i>



{/* <!-- Modal --> */}
<div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div className="modal-dialog" role="document">
<div className="modal-content">
  <div className="modal-header">
    <h5 className="modal-title" id="exampleModalLabel">Close FD</h5>
    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div className="modal-body">
    



     {/* modal form start */}
     
     <div className="row clearfix">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="card modalFormCard">
              <div className="header">
                <h2>Close RD</h2>
              </div>
              <div className="body">
                <div className="demo-masked-input">
                  <div className="row clearfix">
                   
                 
                 
                  <div className="col-md-8">
                          <b>ID</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="number"
                                className="form-control date"
                                placeholder="Enter id"
                                value={formData.rdid}
                                  onChange={(e) => handleInputChange(e)}
                                  name="rdid" id="rdid"
                              />
                            </div>
                          </div>
                        </div>



                        <div className="col-md-8">
                       <b>Start Date</b>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">date_range</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="date"
                             className="form-control date"
                             placeholder="Select Start Date"
                             name="startDate" id="startDate"
                             value={formData.startDate ? formData.startDate.split('T')[0] : ''}
                             readOnly
                             onChange={(e) => handleInputChange(e)}
                           />
                         </div>
                       </div>
                       </div>

<div className="col-md-8">
  <b>End Date</b>
  <div className="input-group">
    <span className="input-group-addon">
      <i className="material-icons">date_range</i>
    </span>
    <div className="form-line">
      <input
        type="date"
        className="form-control date"
        placeholder="Select End Date"
        name="endDate" id="endDate"
        value={formData.endDate ? formData.endDate.split('T')[0] : ''}
        // readOnly
        onChange={(e) => handleInputChange(e)}
      />
    </div>
  </div>
</div>

                        <div className="col-md-8">
                          <b>Total Days</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="number"
                                className="form-control date"
                                name="days" id="days"
                                placeholder='Enter total days'
                                value={formData.days}
                                onChange={(e) => handleInputChange(e)}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-8">
                          <b>RD Amount</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="number"
                                className="form-control date"
                                placeholder='Enter FD amount'
                                name="rdmonthlyAmount" id="rdmonthlyAmount"
                                value={formData.rdmonthlyAmount}
                                  onChange={(e) => handleInputChange(e)}
                              />
                            </div>
                          </div>
                        </div>

                      

                        <div className="col-md-8">
                          <b>Total Amount</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="number"
                                className="form-control date"
                                placeholder='Enter Total amount'
                                value={formData.totalAmount}
                                onChange={(e) => handleInputChange(e)}
                                name="totalAmount" id="totalAmount"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-8">
                          <b>cheque Number</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="text"
                                className="form-control date"
                                placeholder='Enter cheque number'
                                name="chequeNumber" id="chequeNumber"
                                value={formData.chequeNumber}
                                onChange={(e) => handleInputChange(e)}

                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-8">
                          <b>Receipt Number</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="number"
                                className="form-control date"
                                placeholder='Enter Reciept number'
                                name="receiptNo" id="receiptNo"
                                value={formData.receiptNo}
                                onChange={(e) => handleInputChange(e)}
                              />
                            </div>
                          </div>
                        </div>


                   

                    <button type="button" onClick={handleDelete} className="btn bg-indigo btn-block btn-lg waves-effect">Submit</button>
                    <button type="button" className="btn bg-indigo btn-block btn-lg waves-effect closeButton" data-dismiss="modal">Close</button>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
   {/* Modal form ends */}


  </div>
</div>
</div>
</div>
{/* Modal End */}
</div>
  )
}

export default CloseRDmodal
