import React, { useEffect, useState } from 'react'
import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import 'jspdf-autotable'; // Import jspdf-autotable for table rendering in PDF

const
  EmergencyLoanHistoryUserData
    = ({ loanData }) => {


      console.log("Emergency loan history table data", loanData);
      const [searchTerm, setSearchTerm] = useState("");
      const [rowsPerPage, setRowsPerPage] = useState(null); // Default rows per page
      const [loading, setLoading] = useState(false); // State to manage loading indicator



      useEffect(() => {
        // Set rowsPerPage to the total number of rows initially
        if (loanData && loanData.length > 0) {
          setRowsPerPage(loanData.length);
        }
      }, [loanData]);

      if (!loanData || loanData.length === 0) {
        return <div>No data available</div>;
      }

      const filteredData = loanData.filter((data) => {
        return (
          (data.accountNo && data.accountNo.toString().includes(searchTerm.toString())) ||
          (data.fullName && data.fullName.toLowerCase().includes(searchTerm.toLowerCase()))
        );
      });

      const handleRowsPerPageChange = (e) => {
        const value = e.target.value;
        setRowsPerPage(value === "all" ? filteredData.length : parseInt(value));
      };


      const downloadPDF = async () => {
        setLoading(true); // Show loading indicator
        try {
          const doc = new jsPDF();

          // Calculate the height of the table content
          const input = document.getElementById('pdf-container');
          const { width, height } = input.getBoundingClientRect();

          // Set the dimensions of the canvas to match the dimensions of the table
          const canvas = await html2canvas(input, { width, height });
          const imgData = canvas.toDataURL('image/png');

          // Add image to PDF with appropriate dimensions
          const imgWidth = doc.internal.pageSize.getWidth();
          const imgHeight = (height * imgWidth) / width;
          doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

          doc.save('userdata.pdf');
        } catch (error) {
          console.error('Error generating PDF:', error);
        } finally {
          setLoading(false); // Hide loading indicator
        }
      };

      const downloadExcel = () => {
        const table = document.getElementById('pdf-container');
        const ws = XLSX.utils.table_to_sheet(table);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, 'userdata.xlsx');
      };

      const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB'); // Adjust locale based on your preference
      }
      return (
        <div>

          <div className="dt-buttons" style={{ zIndex: "1000" }}>



            {/* <button className="dt-button buttons-excel buttons-html5" onClick={downloadExcel}>
                <span>Excel</span>
              </button>
              <button className="dt-button buttons-pdf buttons-html5" onClick={downloadPDF}>
                <span>PDF</span>
              </button> */}
            <a
              style={{ zIndex: "1000" }}
              className="dt-button buttons-excel buttons-html5"
              onClick={downloadExcel}
              tabIndex="0"
              aria-controls="DataTables_Table_1"
              href="#"
            >
              <span>Excel</span>
            </a>
            <a
              className="dt-button buttons-pdf buttons-html5"
              onClick={downloadPDF}
              tabIndex="0"
              aria-controls="DataTables_Table_1"
              href="#"
            >
              <span>PDF</span>
            </a>


          </div>
          <div className="sticky-top" style={{ display: "flex", marginTop: "10px" }}>
            <div id="DataTables_Table_1_wrapper" className="dataTables_wrapper form-inline dt-bootstrap">
              <div id="DataTables_Table_1_filter" className="dataTables_filter">
                <label>
                  Search:
                  <input
                    type="search"
                    className="form-control input-sm"
                    placeholder=""
                    aria-controls="DataTables_Table_1"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </label>
              </div>

              <label>Show entries
                <select
                  name="DataTables_Table_0_length"
                  aria-controls="DataTables_Table_0"
                  onChange={handleRowsPerPageChange}
                  value={rowsPerPage === filteredData.length ? "all" : rowsPerPage}
                  className="form-control input-sm"
                >
                  <option value="all">All</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                entries
              </label>
            </div>
          </div>

          <div id="pdf-container">
            {loading ? (
              <p>Loading...</p>
            ) : (

              <table>
                <thead>
                  <tr>
                    {/* <th>ID</th> */}
                    <th>Account Number</th>
                    {/* <th>FD Certificate Number</th> */}
                    <th>Full Name</th>
                    <th>EMI</th>
                    <th>Principle Amount</th>
                    <th> Interest Amount</th>
                    <th> Date</th>
                    <th>Loan balance Amount</th>

                  </tr>
                </thead>
                <tbody>

                  {/* {loanData.map((data, index) => ( */}
                  {filteredData.slice(0, rowsPerPage).map((data, index) => (
                    <tr key={index}>
                      <td>{data.accountNo}</td>
                      <td>{data.fullName}</td>
                      <td>{data.emi}</td>
                      <td>{data.principleAmount}</td>
                      <td>{data.interestAmount}</td>
                      {/* console.log(emi); */}
                      <td>{formatDate(data.date)}</td>
                      <td>{data.loanBalanceAmount}</td>
                    </tr>
                  ))}




                </tbody>
              </table>
            )}
          </div>
        </div>
      )
    }

export default EmergencyLoanHistoryUserData