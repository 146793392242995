import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import AdminSideBar from '../../AdminSideBar/AdminSideBar'
import AdminTopBar from '../../AdminTopBar/AdminTopBar'
import CloseRDmodal from './CloseRDmodal'
import axios from 'axios'
import CloseRDData from './CloseRDData'

const CloseRD = () => {

  const [searchAccountNo, setSearchAccountNo] = useState('');
  const [rdDetails, setRdDetails] = useState([]);
  const [formData, setFormData] = useState({
    totalAmount: '',
    interestAmount: '',
    days: '',
    rdid: '',
    accountNo: '',
    accountName: '',
    rdno: '',
    rdmonthlyAmount: '',
    startDate: '',
    receiptNo: '',
    endDate:'',
    chequeNumber: '',
    previosBalance:'',
    interestId: '',
    modifiedDate: '',
    changeInterestId: '',
    // interestAoumnt: '',
    // totalFdamount: ''

  });

  const [editId, setEditId] = useState(null);
  const handleSearch = () => {
    axios.get(`https://103.50.212.98:4431/api/RdDetails/AccountNo?AccountNo=${searchAccountNo}`)
      .then(response => {
        const { data } = response;
        if (data && data.result) {
          setRdDetails(data.result); // Set the result to rdDetails state
        } else {
          setRdDetails([]); // No data found, so reset the rdDetails state
        }
      })
      .catch(error => {
        console.error('Error fetching RD details:', error);
        // Optionally, you can set an error state here or show an error message
      });
  };
 
  const handleEdit = (id) => {
    // Handle edit action
    const userToEdit = rdDetails.find(details => details.rdid === id);
        if (userToEdit) {
            setFormData({
              totalAmount: userToEdit.totalAmount,
              interestAmount: userToEdit.interestAmount,
              days: userToEdit.days,
              rdid: userToEdit.rdid,
              accountNo: userToEdit.accountNo,
              accountName: userToEdit.accountName,
              rdno: userToEdit.rdno,
              rdmonthlyAmount: userToEdit.rdmonthlyAmount,
              startDate: userToEdit.startDate,
              receiptNo: userToEdit.receiptNo,
              endDate: userToEdit.endDate,
              chequeNumber: userToEdit.chequeNumber,
              previosBalance: userToEdit.previosBalance,
              interestId: userToEdit.interestId,
              modifiedDate: userToEdit.modifiedDate,
              changeInterestId: userToEdit.changeInterestId,

                // name: studentToEdit.name,
                // age: studentToEdit.age,
                // fees: studentToEdit.fees
            });
            setEditId(id);
        }
};
const handleInputChange = (e) => {
  const { name, value } = e.target;
  setFormData({
      ...formData,
      [name]: value
  });
};



  return (
    <div>
       <div className="theme-red">
        {/* <!-- Page Loader --> */}
        {/* <Loader /> */}
        {/* <!-- #END# Page Loader -->

   
  <!-- Search Bar --> */}
        <div className="search-bar">
          <div className="search-icon">
            <i className="material-icons">search</i>
          </div>
          <input type="text" placeholder="START TYPING..." />
          <div className="close-search">
            <i className="material-icons">close</i>
          </div>
       
        </div>
        {/* <!-- #END# Search Bar -->
   
   
  <!-- Top Bar --> */}
       <AdminTopBar/>
        {/* <!-- #Top Bar --> */}
      
{/* sideBar */}

<AdminSideBar  />
{/* end sideBar */}

        <section className="content">
             <Link to="/">  <h4 >DASHBOARD</h4></Link>
          <div className="container-fluid">
            <div className="block-header">
            
            </div>

          
              {/* form starts */}
            {/* <!-- Masked Input --> */}

            <div className="row clearfix">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2>Close RD Form</h2>
                  </div>
                  <div className="body">
                    <div className="demo-masked-input">
                      <div className="row clearfix">
                       
                                            

                        <div className="col-md-8">
                          <b>Account Number (खाते क्रमांक)</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="number"
                                className="form-control date"
                                placeholder="Enter Account Number"
                                value={searchAccountNo}
                                onChange={(e) => setSearchAccountNo(e.target.value)}
                                 />
                              <button type="button"  onClick={handleSearch} className="btn bg-indigo btn-block btn-lg waves-effect primaryButton secondaryButton">Search</button>
                            </div>
                          </div>
                        </div>
                        {/* <button type="button" className="btn btn-primary ">Search</button> */}
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- #END# Masked Input --> */}
            {/* form starts */}


  {/* Table start */}

  <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2>Close RD Member Table</h2>

                  </div>
                  <div className="body">
                    <div className="table-responsive">
                      <div
                        id="DataTables_Table_1_wrapper"
                        className="dataTables_wrapper form-inline dt-bootstrap"
                      >
                        
                        {/* <label>Show <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="form-control input-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option></select> entries</label> */}



                        <table
                          className="table table-bordered table-striped table-hover dataTable js-exportable"
                          id="DataTables_Table_1"
                          role="grid"
                          aria-describedby="DataTables_Table_1_info"
                        >
                          
                          <tbody>
                          <CloseRDData rdDetails={rdDetails} handleEdit={handleEdit} formData={formData} handleInputChange={handleInputChange}/>

                      
                          </tbody>
                        </table>
                        <div
                          className="dataTables_info"
                          id="DataTables_Table_1_info"
                          role="status"
                          aria-live="polite"
                        >
                          Showing 1 to 10 of 57 entries
                        </div>
                        <div
                          className="dataTables_paginate paging_simple_numbers"
                          id="DataTables_Table_1_paginate"
                        >
                          <ul className="pagination">
                            <li
                              className="paginate_button previous disabled"
                              id="DataTables_Table_1_previous"
                            >
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="0"
                                tabIndex="0"
                              >
                                Previous
                              </a>
                            </li>
                            <li className="paginate_button active">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="1"
                                tabIndex="0"
                              >
                                1
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="2"
                                tabIndex="0"
                              >
                                2
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="3"
                                tabIndex="0"
                              >
                                3
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="4"
                                tabIndex="0"
                              >
                                4
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="5"
                                tabIndex="0"
                              >
                                5
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="6"
                                tabIndex="0"
                              >
                                6
                              </a>
                            </li>
                            <li
                              className="paginate_button next"
                              id="DataTables_Table_1_next"
                            >
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="7"
                                tabIndex="0"
                              >
                                Next
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
         {/*  Table end */}


          </div>
        </section>
      </div>
    </div>
  )
}

export default CloseRD
