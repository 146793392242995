import React from 'react'
import CloseRDmodal from './CloseRDmodal'

const CloseRDData = ({ rdDetails, handleEdit, formData, handleInputChange }) => {
  return (
    <div>
            <table className="table table-bordered table-striped table-hover dataTable js-exportable">
      <thead>
        <tr>
        
          <th>RD Id</th>
          <th>Total Amount</th>
          <th>Account Number</th>
          <th>Full Name</th>
          <th>RD Number</th>
          <th>RD Monthly Amount</th>
          <th>Start Date</th>
          <th>receipt No</th>
          <th>End Date</th>
          <th>Cheque Number</th>
          <th>Action</th>
         

        </tr>
      </thead>
      <tbody>
        {rdDetails.length === 0 ? (
          <tr>
            <td colSpan="12">No data available</td>
          </tr>
        ) : (
          // 
          rdDetails.map((details, index) => (
            <tr key={details.rdid}>
              {/* <td>{index + 1}</td> */}
              <td>{details.rdid}</td>
              <td>{details.totalAmount}</td>
              <td>{details.accountNo}</td>
              <td>{details.fullName}</td>
              <td>{details.rdno}</td>
              <td>{details.rdmonthlyAmount}</td>
              <td>{details.startDate}</td>
              <td>{details.receiptNo}</td>
              <td>{details.endDate}</td>
              <td>{details.chequeNumber}</td>
              <td><CloseRDmodal formData={formData} handleInputChange={handleInputChange}  handleEdit={() => handleEdit(details.rdid)} /></td>
             
            </tr>
          ))
        )}
      </tbody>
    </table>
    </div>
  )
}

export default CloseRDData
