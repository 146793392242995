import React, { useEffect, useState } from 'react'
import AdminTopBar from '../../AdminTopBar/AdminTopBar'
import AdminSideBar from '../../AdminSideBar/AdminSideBar'
import { Link } from 'react-router-dom'
// import AddFDModal from './AddFDModal'
import { Button, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import AddFDdata from './AddFDdata'
// import { Submit } from '../../Service';

const AddFD = () => {
  
  
  const [users, setUsers] = useState([])
  const [userData, setUserData] = useState(null); // State to store user data (auto fill fields)
  const [accountNo, setAccountNo] = useState(''); // State to store account number
  const [fdAmountError, setFdAmountError] = useState(false);
  const [fdDateError, setFdDateError] = useState(false);
  // Add a state to hold form data
 
  const [fdCertificateNo, setFdCertificateNo] = useState('');
  const [formData, setFormData] = useState({
    accountNo: '',
    accountName: '',
    fdCertificateNo: '',
    fdAmount: '',
    fdDate: '',
    reciptNo: '',
    chequeNo: '',
    // fdId: 0,
    // fdYear: new Date().toISOString(),
    // fdCloseDate: new Date().toISOString(),
    // isDelete: true,
    // changeInterestId: 0,
    // interestAoumnt: 0,
    // totalFdamount: 0,
  });

  useEffect(() => {
    const fetchFdCertificateNo = async () => {
      try {
        const response = await axios.get('https://103.50.212.98:4431/api/FdDetails/cerNo');
        const { result } = response.data;
        setFdCertificateNo(result);
      } catch (error) {
        console.log('Error fetching FD Certificate Number:', error);
      }
    };

    fetchFdCertificateNo(); // Trigger the API call when component mounts
  }, []); // Empty dependency array means this effect runs only once after the initial render

  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  
  const handleFdCertificateNoChange = (e) => {
    const { value } = e.target;
    setFdCertificateNo(value); // Update the fdCertificateNo state directly
  };

  const fetchUserDetails = async (accountNo) => {
    try {
      const res = await axios.get(`https://103.50.212.98:4431/api/FdDetails/SearchButton?AccountNo=${accountNo}`);
      const userData = res.data.result;
      setUserData(userData);
      // Update accountName based on fetched user data
      setFormData({
        ...formData,
        accountName: userData.fullName,
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Function to handle form submission
const handleSubmit = async (event) => {
  event.preventDefault();

   // Check if FD amount is empty
   if (!formData.fdAmount) {
    setFdAmountError(true);
  } else {
    setFdAmountError(false);
  }

  // Check if FD date is empty
  if (!formData.fdDate) {
    setFdDateError(true);
  } else {
    setFdDateError(false);
  }

  // If either FD amount or FD date is empty, exit the function
  if (!formData.fdAmount || !formData.fdDate) {
    return;
  }

  try {
    // Add fdCertificateNo to the formData object
    const formDataWithCertificateNo = {
      ...formData,
      fdCertificateNo: fdCertificateNo,
    };

    const response = await axios.post('https://103.50.212.98:4431/api/FdDetails/post', formDataWithCertificateNo);
    console.log('Form data submitted successfully:', response.data);
    alert("Form data submitted successfully")
    // Handle success or navigate to another page
    // Reset the form fields to initial empty state
    setFormData({
      accountNo: '',
      accountName: '',
      fdCertificateNo: '',
      fdAmount: '',
      fdDate: '',
      reciptNo: '',
      chequeNo: '',
    });
  } catch (error) {
    console.error('Error submitting form data:', error);
    // Handle error state or show error message
  }
};
  


 




  // table data code
  const fetchUsers = async (url) => {
    try {
      const res = await axios.get(url); // Use axios.get instead of fetch
      const Tdata = res.data; // Axios response data is directly accessible as res.data
      console.log(Tdata, " Add FD table data"); // Log the data here to inspect its structure
      setUsers(Tdata); // Set the fetched data to the users state
    } catch (error) {
      console.log(error);
    }
  };

  const API = "https://103.50.212.98:4431/api/FdDetails/get";

  useEffect(() => {
    fetchUsers(API);
  }, []);

   // Prevent accidental value changes on scroll
   const handleScroll = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
 

  return (
    <div>
      <div className="theme-red">
        {/* <!-- Page Loader --> */}
        {/* <Loader /> */}
        {/* <!-- #END# Page Loader -->
   
   
   
   
   
  <!-- Search Bar --> */}
        <div className="search-bar">
          <div className="search-icon">
            <i className="material-icons">search</i>
          </div>
          <input type="text" placeholder="START TYPING..." />
          <div className="close-search">
            <i className="material-icons">close</i>
          </div>

        </div>
        {/* <!-- #END# Search Bar -->
   
   
   
   
  <!-- Top Bar --> */}
        <AdminTopBar />
        {/* <!-- #Top Bar --> */}



        {/* sideBar */}


        <AdminSideBar />
        {/* end sideBar */}

        <section className="content">
          <Link to="/">  <h4 >DASHBOARD</h4></Link>
          <div className="container-fluid">
            <div className="block-header">

            </div>


            {/* form starts */}
            {/* <!-- Masked Input --> */}
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="DedictionForm">


                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="card">
                      <div className="header">
                        <h2>ADD FD(FIXED DEPOSIT)</h2>
                      </div>
                      <div className="body">
                        <div className="demo-masked-input">
                          <div className="row clearfix">




                            <div className="col-md-8">
                              <b>Account Number (खाते क्रमांक)</b>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="material-icons">person</i>
                                </span>
                                <div className="form-line">
                                <input
                                type="number"
                                name="accountNo"
                                className="form-control date"
                                placeholder="Enter Account Number"
                                value={formData.accountNo}
                                onChange={handleInputChange}
                              />
                                <button
                                type="button"
                                onClick={() => fetchUserDetails(formData.accountNo)}
                                className="btn bg-indigo btn-block btn-lg waves-effect primaryButton secondaryButton"
                              >
                                Search
                              </button>
                                </div>
                              </div>
                            </div>
                            {/* <button type="button" className="btn btn-primary ">Search</button> */}






                            <div className="col-md-8">
                              <b>Full Name (पूर्ण नाव)</b>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="material-icons">person</i>
                                </span>
                                <div className="form-line">
                                <input
                                type="text"
                                name="accountName"
                                className="form-control date"
                                placeholder="Account Name"
                                value={formData.accountName}
                                 readOnly
                              
                              />
                                </div>
                              </div>
                            </div>




                            <div className="col-md-8">
                              <b>FD Certificate Number (FD प्रमाणपत्र क्रमांक)</b>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="material-icons">person</i>
                                </span>
                                <div className="form-line">

                                <input
                                  type="text"
                                  name="fdCertificateNo"
                                  className="form-control date"
                                  value={fdCertificateNo} // Bind input value directly to the state
                                  onChange={handleFdCertificateNoChange} // Use the new onChange handler
                                  readOnly // Mark input field as read-only (optional based on your requirement)
                                />

                                </div>
                              </div>
                            </div>



                            <div className="col-md-8">
                              <b>FD Amount (FD रक्कम)</b>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="material-icons">person</i>
                                </span>
                                <div className="form-line">
                                  <input
                                    type="number"
                                    name='fdAmount'
                                    // className="form-control "
                                    className={`form-control ${fdAmountError ? 'is-invalid' : ''}`}
                                    placeholder='Enter FD Amount (in Rs)'
                                   
                                    value={formData.fdAmount}
                                    onChange={handleInputChange}
                                  />
                                </div>
                              </div>
                           {fdAmountError && <p style={{ color: "red" }}>FD Amount is required</p>}
                          {/* <p style={{ color: "red" }}>{errors?.fdAmount?.message}</p> */}
                         
                            </div>






                            <div className="col-md-8">
                              <b>FD Date (FD तारीख )</b>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="material-icons">person</i>
                                </span>
                                <div className="form-line">
                                  <input
                                    type="date"
                                    name='fdDate'
                                    // className="form-control"
                                    className={`form-control ${fdDateError ? 'is-invalid' : ''}`}
                                    value={formData.fdDate}
                                    onChange={handleInputChange}
                                 />
                                </div>
                              </div>
                              {fdDateError && <p style={{ color: "red" }}>FD Date is required</p>}


                            </div>




                            <div className="col-md-8">
                              <b>Receipt Number</b>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="material-icons">person</i>
                                </span>
                                <div className="form-line">
                                  <input
                                    type="number"
                                    name='reciptNo'
                                    className="form-control date"
                                    placeholder='Enter Receipt Number'
                                    value={formData.reciptNo}
                                   onChange={handleInputChange}
                                  />
                                </div>
                              </div>
                            </div>


                            <div className="col-md-8">
                              <b>Cheque Number</b>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="material-icons">person</i>
                                </span>
                                <div className="form-line">
                                  <input
                                    type="text"
                                    name='chequeNo'
                                    className="form-control date"
                                    placeholder='Enter chequeNo Number'
                                    value={formData.chequeNo}
                                    onChange={handleInputChange}
                                  />
                                </div>
                              </div>
                            </div>

                          

                            <Button type="submit"  variant='primary' className="btn btn-block btn-lg waves-effect">Submit</Button>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </Form.Group>
            </Form>
            {/* <!-- #END# Masked Input --> */}
            {/* form starts */}




            {/* Table start */}
                
         <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2>Add FD Table</h2>

                  </div>
                  <div className="body">
                    <div className="table-responsive">
                      <div
                        id="DataTables_Table_1_wrapper"
                        className="dataTables_wrapper form-inline dt-bootstrap"
                      >
                        
                        {/* <label>Show <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="form-control input-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option></select> entries</label> */}



                        <table
                          className="table table-bordered table-striped table-hover dataTable js-exportable"
                          id="DataTables_Table_1"
                          role="grid"
                          aria-describedby="DataTables_Table_1_info"
                        >
                          
                          <tbody>
                            <AddFDdata Users={users} />

                      
                          </tbody>
                        </table>
                        <div
                          className="dataTables_info"
                          id="DataTables_Table_1_info"
                          role="status"
                          aria-live="polite"
                        >
                          Showing 1 to 10 of 57 entries
                        </div>
                        <div
                          className="dataTables_paginate paging_simple_numbers"
                          id="DataTables_Table_1_paginate"
                        >
                          <ul className="pagination">
                            <li
                              className="paginate_button previous disabled"
                              id="DataTables_Table_1_previous"
                            >
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="0"
                                tabIndex="0"
                              >
                                Previous
                              </a>
                            </li>
                            <li className="paginate_button active">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="1"
                                tabIndex="0"
                              >
                                1
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="2"
                                tabIndex="0"
                              >
                                2
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="3"
                                tabIndex="0"
                              >
                                3
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="4"
                                tabIndex="0"
                              >
                                4
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="5"
                                tabIndex="0"
                              >
                                5
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="6"
                                tabIndex="0"
                              >
                                6
                              </a>
                            </li>
                            <li
                              className="paginate_button next"
                              id="DataTables_Table_1_next"
                            >
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="7"
                                tabIndex="0"
                              >
                                Next
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

           
            {/*  Table end */}










          </div>
        </section>
      </div>
    </div>
  )
}

export default AddFD
